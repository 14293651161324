import React, { useState } from 'react';
import { Button } from 'components/elements';
import { useDispatch } from 'react-redux';
import {
  getBidsBySiteIdInCustomer,
  getStartedProjects,
  updateBidStatus,
} from 'store/bid/bidActions';
import { toast } from 'react-toastify';
import { StartProjectConfirmationPopup } from './StartProjectConfirmationPopup';

// Images
import EarthIllustration from 'assets/svgs/Earth3.svg';
import TickCircleIcon from 'assets/svgs/TickCircle.svg';
import DocumentEdit from 'assets/svgs/DocumentEdit.svg';
import Download from "assets/svgs/Download.svg";
import { TechnologyList } from 'components/macros/Technologies';
import FileAttachment from "components/global/FileAttachments";

const FinalBidSubmitted = ({ bid }) => {
  const dispatch = useDispatch();
  const [popup, setPopup] = useState(false);

  const handleClose = () => {
    setPopup(false);
  };

  const onClickStartProject = () => {
    handleClose();
    const payload = {
      id: bid?._id,
      data: {
        status: "completed",
      },
    };
    dispatch(updateBidStatus(payload))
      .unwrap()
      .then((res) => {
        dispatch(getBidsBySiteIdInCustomer(bid?.siteId?._id));
        dispatch(getStartedProjects());
        toast.success("Final quote accepted!", { toastId: "finalQuote" });
      })
      .catch((err) => {
        toast.error(err || "Something went wrong", { toastId: "errorFinal" });
      });
  };
  return (
    <div className="relative max-w-[720px] bg-white flex-column lg:row-flex gap-4 px-2 md:px-4 xl:px-6 py-4 md:py-6 xl:py-8 rounded-xl shadow-md">
      <div className="flex-1 flex-column gap-2 md:gap-4 lg:gap-8">
        <div className="row-flex items-center gap-1 md:gap-2">
          <img
            src={TickCircleIcon}
            alt="doc"
            className="w-[0.9rem] h-[1rem] md:w-[22px] md:h-[24px]"
          />
          <p className="text-black text-base lg:text-lg capitalize font-[500]">
            Final Bid Submitted
          </p>
        </div>
        <p className="text-xs md:text-sm text-gray font-[400]">
          You have received the final bid from your installer. You can choose to
          accept or decline the offer. If the installer has added any
          attachments, the document will be viewed below. If you have any
          queries regarding the quote, use the messaging function to contact the
          installer. Once you have accepted the quote, you’ll be able to proceed
          with the installation. The project will move into the project started
          folder.
        </p>
        <div className="flex-column gap-2 md:gap-4 lg:ml-7">
          <Cost
            name="Material Cost"
            cost={bid?.cost?.finalBid?.materialCost || 15000}
          />
          <Cost
            name="Labour Cost"
            cost={bid?.cost?.finalBid?.laborCost || 15000}
          />
          <Cost
            name="Access Cost"
            cost={bid?.cost?.finalBid?.additionalCost || ""}
          />
          <Cost name="Total Cost" cost={bid?.cost?.finalBid?.totalCost} />

          {bid?.cost?.finalBid?.attachments?.length > 0 && (
            <FileAttachment
              title="Final Attachments"
              attachments={bid?.cost?.finalBid?.attachments}
            />
          )}
        </div>
      </div>

      <div className="flex-column">
        <img
          src={EarthIllustration}
          alt="Earth"
          className="hidden lg:block m-auto"
        />
        <TechnologyList
          technologies={bid?.technologies}
          oldTechnologies={bid?.siteId?.technologies}
        />

        {bid?.status === "completed" ? null : (
          <Button
            // onClick={onClickStartProject}
            onClick={() => setPopup(true)}
            text="Accept Final Bid"
            variant="bordered"
            className="max-w-[186px] mt-auto md:ml-auto"
          />
        )}
      </div>

      <div className="hidden xl:block absolute top-0 left-[34px] z-[0] w-[1px] h-4 md:h-6 xl:h-8 bg-primary">
        <div className="absolute top-0 left-1/2 transform -translate-x-1/2"></div>
      </div>

      <StartProjectConfirmationPopup
        open={popup}
        handleClose={handleClose}
        handleStartProject={onClickStartProject}
      />
    </div>
  );
};

const Cost = ({ name, cost }) => (
  <div className='flex-column gap-1'>
    <p className='text-gray text-xs md:text-[15px] font-[400]'>{name} (£)</p>
    <p className='text-sm md:text-lg text-primary font-[600]'>£ {cost}</p>
  </div>
);

export { FinalBidSubmitted };
