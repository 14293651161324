import React, { useState, useEffect } from "react";
import EarthIllustration from "assets/svgs/Earth2.svg";
import BoxSearchIcon from "assets/svgs/BoxSearch.svg";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { addProjectDate, updateProjectDate } from "store/bid/bidActions";
import WatchIcon from "assets/svgs/Watch.svg";
import dayjs from "dayjs";
import { Button } from "components/elements";
import { DatePicker } from "antd";

const ProjectCompletedDate = ({
  userRole,
  projectStartedDate,
  projectCompletedDate,
  isCompleted,
}) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [isProjectCompleted, setIsProjectCompleted] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [error, setError] = useState("");

  useEffect(() => {
    if (userRole === "customer" && projectCompletedDate) {
      setIsProjectCompleted(true);
    }
  }, [userRole, projectCompletedDate]);

  const handleRadioChange = (value) => {
    const isCompleted = value === "yes";
    const currentDate = dayjs().format("YYYY-MM-DD");

    setIsProjectCompleted(isCompleted);
    setSelectedDate(currentDate);
  };

  const handleSubmit = () => {
    if (!selectedDate) {
      setError("Please select a valid date.");
      return;
    }

    const payload = {
      id,
      isCompleted: isProjectCompleted,
      projectEndDate: selectedDate,
    };

    dispatch(updateProjectDate(payload));
    setError("");
  };

  const handleSubmitInstallationDate = () => {
    if (!selectedDate) {
      setError("Please select a valid date.");
      return;
    }

    const formattedDate = dayjs(selectedDate).format("YYYY-MM-DD");
    dispatch(addProjectDate({ projectStartedDate: formattedDate, bidId: id }));
    setError("");
  };

  if (!projectStartedDate && userRole === "customer") return;

  return (
    <div className="relative max-w-[720px] bg-white flex flex-col lg:flex-row gap-4 px-2 md:px-4 xl:px-6 py-4 md:py-6 xl:py-8 rounded-xl shadow-md">
      <div className="flex-1 flex flex-col gap-2 md:gap-4">
        <div className="flex items-center gap-1 md:gap-2">
          <img
            src={BoxSearchIcon}
            alt="Search"
            className="w-[0.9rem] h-[1rem] md:w-[22px] md:h-[24px]"
          />
          <p className="text-black text-base lg:text-lg capitalize font-[400]Installation Date">
            {userRole === "customer"
              ? "Is the Project Completed?"
              : "Set Installation Date"}
          </p>
        </div>
        {((userRole === "customer" && !projectCompletedDate) ||
          (userRole === "installer" && !projectStartedDate)) && (
          <p className="text-base text-gray font-[400]">
            {userRole === "customer"
              ? "Select 'Yes' to confirm the project's completion date."
              : "Please select the date when the installation is scheduled to begin."}
          </p>
        )}

        {projectStartedDate && (
          <div className="flex-column gap-2">
            <p className="text-base text-gray">Installation Date</p>
            <p className="row-flex items-center gap-2 text-sm md:text-base text-[#5E6278] capitalize">
              <span>
                <img
                  src={WatchIcon}
                  alt="watch icon"
                  className="h-[20px] w-[18px]"
                />
              </span>
              <span>{dayjs(projectStartedDate).format("MMMM DD, YYYY")}</span>
            </p>
          </div>
        )}

        {projectCompletedDate && (
          <div className="flex-column gap-2">
            <p className="text-base text-gray">
              {isCompleted
                ? "Project Completed Date"
                : "Project Marked as Incompleted"}
            </p>
            <p className="row-flex items-center gap-2 text-sm md:text-base text-[#5E6278] capitalize">
              <span>
                <img
                  src={WatchIcon}
                  alt="watch icon"
                  className="h-[20px] w-[18px]"
                />
              </span>
              <span>{dayjs(projectCompletedDate).format("MMMM DD, YYYY")}</span>
            </p>
          </div>
        )}

        {userRole === "installer" && !projectStartedDate && (
          <div>
            <DatePicker
              className="custom-date-picker w-full"
              // value={moment(siteVisitMorning)}
              onChange={(e) => setSelectedDate(e)}
            />
          </div>
        )}

        {userRole === "customer" && !projectCompletedDate && (
          <div className="flex flex-col mt-4 gap-2">
            <label className="flex items-center gap-2">
              <input
                type="radio"
                name="time"
                id="time-yes"
                onChange={() => handleRadioChange("yes")}
                className="h-4 w-4 appearance-none rounded-full border-2 border-[#8A8A8A] bg-white checked:bg-primary checked:border-[#8A8A8A] focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left mr-2"
              />
              <span className="text-sm md:text-base text-gray">Yes</span>
            </label>
            <label className="flex items-center gap-2">
              <input
                type="radio"
                name="time"
                id="time-no"
                onChange={() => handleRadioChange("no")}
                className="h-4 w-4 appearance-none rounded-full border-2 border-[#8A8A8A] bg-white checked:bg-primary checked:border-[#8A8A8A] focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left mr-2"
              />
              <span className="text-sm md:text-base text-gray">No</span>
            </label>
          </div>
        )}

        {error && <p className="text-red-500 text-xs mt-1">{error}</p>}
      </div>

      <div className="flex justify-between flex-col">
        {userRole && (
          <div className="hidden lg:block">
            <img
              src={EarthIllustration}
              alt="Earth Illustration"
              className="m-auto"
            />
          </div>
        )}
        {((userRole === "installer" && !projectStartedDate) ||
          (userRole !== "installer" && !projectCompletedDate)) &&
          selectedDate && (
            <Button
              text="Submit"
              variant="bordered"
              className="ml-auto mt-5"
              type="button"
              onClick={
                userRole === "installer"
                  ? handleSubmitInstallationDate
                  : handleSubmit
              }
            />
          )}
      </div>
    </div>
  );
};

export { ProjectCompletedDate };
