import React from "react";

const NoDataPlaceholder = ({
  message = "No project found",
  height = "90vh",
}) => {
  return (
    <div className={`w-full h-[${height}] flex items-center justify-center`}>
      <p>{message}</p>
    </div>
  );
};

export default NoDataPlaceholder;
