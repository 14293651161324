import * as yup from 'yup';

export const createBidSchema = yup.object().shape({
  materialCost: yup
    .number('This field must be a number')
    .required('Material cost is required')
    .min(1, 'Material cost must be greater than 0')
    .typeError('Material cost must be greater than 0'),
  laborCost: yup
    .number('This field must be a number')
    .required('Labour cost is required')
    .min(1, 'Labour cost must be greater than 0')
    .typeError('Labour cost must be greater than 0'),
  additionalCost: yup
    .number('This field must be a number')
    .required('Access cost is required')
    .min(1, 'Access cost must be greater than 0')
    .typeError('Access cost must be greater than 0'),
  // totalCost: yup
  //   .number("This field must be a number")
  //   .required("Total cost is required")
  //   .min(0, "Total cost must be greater than 0"),
  // additionalDetails: yup.string().required("Additional details are required"),
});

export const finalBidSchema = yup.object().shape({
  materialCost: yup
    .number('This field must be a number')
    .required('Material cost is required')
    .typeError('Material cost required!')
    .min(1, 'Material cost must be greater than 0')
    .typeError('Material cost must be greater than 0'),
  laborCost: yup
    .number('This field must be a number')
    .required('Labour cost is required!')
    .typeError('Labour cost required!')
    .min(1, 'Labour cost must be greater than 0')
    .typeError('Labour cost must be greater than 0'),
  additionalCost: yup
    .number('This field must be a number')
    .required('Access cost is required')
    .min(1, 'Access cost must be greater than 0')
    .typeError('Access cost must be greater than 0'),
  // totalCost: yup
  //   .number("This field must be a number")
  //   .required("Total cost is required")
  //   .min(0, "Total cost must be greater than 0"),
});
