import React from 'react';
import userMock from 'assets/svgs/UserLarge.svg';
import Camera from 'assets/svgs/Camera.svg';
import ProfileButtons from './ProfileButtons';
import { useSelector } from 'react-redux';

function ProfileBanner({ mode, profileImage, setProfileImage, loading }) {
  const { installers: installer } = useSelector((state) => state.installer);
  const text1 = installer?.companyName || 'Profile';
  const text2 =
    mode === 'display'
      ? 'Lorem ipsum is dummy text which is used for '
      : 'Update your profile details';

  const styles = {
    column: 'flex-column relative gap-8 md:gap-4 w-full py-4 md:py-8 px-1',
    header: 'flex pt-[50px]  lg:pt-[20px] lg:pl-[200px] gap-4 relative',
    imageContainer:
      'min-h-[200px] relative w-full bg-lightGreen shadow-dark rounded-2xl',
    image:
      'min-w-[160px] items-center flex justify-center max-w-[160px] h-[160px] absolute left-0 right-0 m-auto  lg:left-5 lg:m-0 border-white border-2 rounded-full bg-lightGreen',
    heading: 'text-[#344054] text-xl lg:text-2xl font-medium',
    description: 'text-xs lg:text-base text-[#5E6278]',
    buttonGroup: 'flex-column sm:row-flex gap-4',
    cancelButton: 'min-w-[98px]',
    saveButton: 'min-w-[81px]',
    editButton: 'min-w-[125px]',
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfileImage({
          preview: reader.result,
          file: file,
        });
      };
      reader.readAsDataURL(file);
    } else {
      setProfileImage(null);
    }
  };

  return (
    <div className={styles.column}>
      <div className='flex items-center gap-6 bg-lightGreen p-4 rounded-xl'>
        <div className='relative'>
          <img
            src={profileImage?.preview ? profileImage?.preview : userMock}
            className='min-w-[160px] items-center flex justify-center max-w-[160px] h-[160px]  rounded-[100px]'
            alt='User avatar'
          />

          {mode === 'edit' && (
            <div className='p-3 cursor-pointer w-[40px] absolute bottom-1 right-0 rounded-[100px] flex items-center bg-primary'>
              <label htmlFor='filePicker'>
                <input
                  id='filePicker'
                  type='file'
                  className='hidden'
                  onChange={handleFileChange}
                />
                <img
                  src={Camera}
                  className='w-full cursor-pointer'
                  alt='Camera Icon'
                />
              </label>
            </div>
          )}
        </div>

        {/* <div className={styles.header}> */}
        <div className='flex-column sm:row-flex justify-between w-full'>
          <div className=' w-[50%] lg:w-full'>
            <div className={styles.heading}>{text1}</div>
            {/* <div className={styles.description}>{text2}</div> */}
          </div>
        </div>
        <ProfileButtons mode={mode} loading={loading} />
        {/* </div> */}
      </div>
    </div>
  );
}

export { ProfileBanner };
