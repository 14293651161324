import React from 'react';
import DocumentIcon from 'assets/svgs/Document.svg';

const NotificationCard = ({
  title,
  message,
  installerId,
  customerId,
  category,
}) => {
  return (
    <div className='bg-[white] py-6 px-4 w-full max-w-[740px] min-h-[100px] rounded-lg shadow-md'>
      <div className='flex-column gap-2'>
        <span className='flex  justify-between w-full text-center'>
          <div className='flex gap-2'>
            <img src={DocumentIcon} alt='icon' className='w-[22px] h-[24px]' />
            <p className='text-bold text-primary'>{title}</p>
          </div>
          <p className='text-primary'>
            {installerId?.name || customerId?.name}
          </p>
        </span>
        <p className='text-darkGray2 font-medium'>{message}</p>
      </div>
      <p className='text-right text-primary'>{category}</p>
    </div>
  );
};

export { NotificationCard };
