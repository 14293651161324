import { Button, Modal } from 'components/elements';
import React from 'react';

const StartProjectConfirmationPopup = ({
  open,
  handleClose,
  handleStartProject,
}) => {
  return (
    <Modal open={open} handleClose={handleClose} className=''>
      <div className='flex-column items-center justify-center gap-4 md:gap-8 w-[250px] md:w-[400px]'>
        <p className='text-black font-[500] text-lg md:text-2xl leading-6'>
          Project Start Confirmation
        </p>
        <div className='flex flex-col gap-2'>
          <p className='text-base font-[500] text-gray leading-6'>
            Are you sure you want to accept this final quote and start the
            project with this installer? All other quotes/bids will be rejected
            automatically.
          </p>
        </div>
        <div className='flex flex-row gap-4'>
          <Button text='Cancel' variant='' onClick={handleClose} />
          <Button
            text='Start Project'
            variant='bordered'
            onClick={handleStartProject}
          />
        </div>
      </div>
    </Modal>
  );
};

export { StartProjectConfirmationPopup };
