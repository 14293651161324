import {
  createSite,
  getBidRequestsInCustomer,
  getBidsBySiteId,
  getCustomerByIdShortListed,
  getRecommendedInstallersBySiteId,
  getSites,
  getSitesByCustomerId,
  sendInviteToInstaller,
} from './sitesActions';

const { createSlice } = require('@reduxjs/toolkit');

const initialState = {
  loading: false,
  installersLoading: false,
  requestLoading: false,
  error: '',
  sites: [],
  site: {},
  recommendedInstallers: [],
  bids: [],
  shortListedSites: [],
};

const sitesSlice = createSlice({
  name: 'site',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createSite.pending, (state) => {
        state.loading = true;
      })
      .addCase(createSite.fulfilled, (state) => {
        state.loading = false;
        state.error = '';
      })
      .addCase(createSite.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(getSites.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSites.fulfilled, (state, action) => {
        state.sites = action.payload;
        state.error = '';
        state.loading = false;
      })
      .addCase(getSites.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(getSitesByCustomerId.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSitesByCustomerId.fulfilled, (state, action) => {
        state.sites = action.payload;
        state.error = '';
        state.loading = false;
      })
      .addCase(getSitesByCustomerId.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(getCustomerByIdShortListed.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCustomerByIdShortListed.fulfilled, (state, action) => {
        state.shortListedSites = action.payload;
        state.error = '';
        state.loading = false;
      })
      .addCase(getCustomerByIdShortListed.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(getBidsBySiteId.pending, (state) => {
        state.installersLoading = true;
      })
      .addCase(getBidsBySiteId.fulfilled, (state, action) => {
        state.bids = action.payload;
        state.error = '';
        state.installersLoading = false;
      })
      .addCase(getBidsBySiteId.rejected, (state, action) => {
        state.error = action.payload;
        state.installersLoading = false;
      })
      .addCase(getBidRequestsInCustomer.pending, (state) => {
        state.installersLoading = true;
      })
      .addCase(getBidRequestsInCustomer.fulfilled, (state, action) => {
        state.bids = action.payload;
        state.error = '';
        state.installersLoading = false;
      })
      .addCase(getBidRequestsInCustomer.rejected, (state, action) => {
        state.error = action.payload;
        state.installersLoading = false;
      })
      .addCase(getRecommendedInstallersBySiteId.pending, (state) => {
        state.installersLoading = true;
      })
      .addCase(getRecommendedInstallersBySiteId.fulfilled, (state, action) => {
        state.recommendedInstallers = action.payload?.recommendedInstallers;
        state.error = '';
        state.installersLoading = false;
      })
      .addCase(getRecommendedInstallersBySiteId.rejected, (state, action) => {
        state.error = action.payload;
        state.installersLoading = false;
      })
      .addCase(sendInviteToInstaller.pending, (state) => {
        state.requestLoading = true;
      })
      .addCase(sendInviteToInstaller.fulfilled, (state) => {
        state.error = '';
        state.requestLoading = false;
      })
      .addCase(sendInviteToInstaller.rejected, (state, action) => {
        state.error = action.payload;
        state.requestLoading = false;
      });
  },
});

export default sitesSlice.reducer;
