import React, { useState } from 'react';
import { Button } from 'components/elements';

// Images
import EarthIllustration from 'assets/svgs/EarthIllustration.svg';
import DirectNotificationIcon from 'assets/svgs/DirectNotification.svg';

import {} from "store/bid/bidActions";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import InfoIcon from "assets/svgs/Info.svg";
import { Tooltip } from "antd";
import ContactInfoPopup from "./ContactDetailPopup";
import { axiosInstance } from "api/axios";
import { updateBidById, setContactModal } from "store/bid/bidSlice";
import { useDispatch, useSelector } from "react-redux";

const ToolTipContent = (
  <div className="flex flex-col gap-2">
    <p className="text-xs font-bold text-black">Guidelines for Initial Bid</p>
    <p className="text-xs font-[400] leading-4 text-[#667085]">
      Here you can look at different projects where installers have submitted
      their initial bid. Click through the different installers and decide what
      initial bids you would like to shortlist. By shortlisting an initial bid,
      you are allowing the installer to schedule a site visit with you.
    </p>
  </div>
);

const InitialBid = ({ bid, loading }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { openContactModal } = useSelector((state) => state.bid);

  const onClickShortlistBid = async () => {
    const payload = {
      id: bid._id,
      data: {
        status: "shortListed",
      },
    };
    await axiosInstance
      .patch(`/bids/status/${payload.id}`, payload.data)
      .then((res) => {
        toast.success("Bid Shortlisted Successfully!", {
          toastId: "bidUpdate",
        });

        dispatch(updateBidById({ ...bid, status: "shortListed" }));
        setTimeout(() => {
          dispatch(setContactModal(true));
        }, 1200);
      })
      .catch((err) => {
        toast.error(err || "Something went wrong!", {
          toastId: "bidUpdateError",
        });
      });
  };

  return (
    <>
      <div className="relative max-w-[720px] bg-white flex-column lg:row-flex gap-4 px-2 md:px-4 xl:px-6 py-4 md:py-6 xl:py-8 rounded-xl shadow-md">
        <div className="flex-1 flex-column gap-2 md:gap-4">
          <div className="row-flex items-center gap-1 md:gap-2">
            <img
              src={DirectNotificationIcon}
              alt="doc"
              className="w-[0.9rem] h-[1rem] md:w-[22px] md:h-[24px]"
            />
            <p className="text-black text-base lg:text-lg capitalize font-[400]">
              Initial Bid
            </p>
            <Tooltip placement="right" title={ToolTipContent} color="white">
              <button>
                <img src={InfoIcon} alt="" className="h-5 w-5" />
              </button>
            </Tooltip>
          </div>
          <p className="text-xs md:text-sm text-gray font-[400]">
            You have received the initial bid, next you can decide to shortlist
            the installer. An invitation from the installer will be sent to
            schedule a project site visit.
            <br />
          </p>
          <p className="text-xs md:text-sm text-gray font-[400]">
            Please note that the initial bid cost relates to the technologies
            that the installer provides. The technologies that are not included
            in the installation will appear in grey and are not included in the
            initial bid cost.
          </p>
          <div className="flex-column gap-4 lg:ml-7 mt-4">
            <Cost
              name="Material Cost"
              cost={bid?.cost?.initialBid?.materialCost || 15000}
            />
            <Cost
              name="Labour Cost"
              cost={bid?.cost?.initialBid?.laborCost || 5000}
            />
            <Cost
              name="Access Cost"
              cost={bid?.cost?.initialBid?.additionalCost || ""}
            />
            <Cost
              name="Total Cost"
              cost={bid?.cost?.initialBid?.totalCost || 20000}
            />
          </div>
        </div>
        <div className="flex-column">
          <img
            src={EarthIllustration}
            alt="Earth"
            className="hidden lg:block m-auto"
          />
          {bid?.status === "processing" ? (
            <Button
              type="submit"
              onClick={onClickShortlistBid}
              text="Shortlist Initial Bid"
              variant="bordered"
              className="max-w-[186px] mt-auto md:ml-auto"
              loading={loading}
            />
          ) : null}
          <div className="flex items-end flex-col gap-2">
            {bid?.status === "shortListed" && (
              <span className="text-primary italic font-semibold ml-auto">
                Intial bid has been shortlisted
              </span>
            )}
            {!bid?.customerInfo?.email && bid?.status === "shortListed" && (
              <Button
                type="button"
                onClick={() => dispatch(setContactModal(true))}
                text="+ Contact Detail"
                variant="bordered"
                className="max-w-[186px] mt-auto md:ml-auto"
              />
            )}
          </div>
        </div>
        <div className="hidden xl:block absolute top-0 left-[34px] z-[0] w-[1px] h-4 md:h-6 xl:h-8 bg-primary">
          <div className="absolute top-0 left-1/2 transform -translate-x-1/2"></div>
        </div>
      </div>
      <ContactInfoPopup
        id={id}
        bid={bid}
        handleClose={() => dispatch(setContactModal(false))}
        open={openContactModal}
      />
    </>
  );
};

const Cost = ({ name, cost }) => (
  <div className='flex-column gap-1'>
    <p className='text-gray text-xs md:text-[15px] font-[400]'>{name} (£)</p>
    <p className='text-sm md:text-lg text-primary font-[600]'>£ {cost}</p>
  </div>
);

export { InitialBid };
