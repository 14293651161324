import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Alert, ConfigProvider, DatePicker } from "antd";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Button } from "components/elements";
import { BID_TYPES } from "mock";
import { FinalQuotePopup } from "./FinalQuotePopup";
import { TimePopup } from "./SetTImePopup";
import { yupResolver } from "@hookform/resolvers/yup";
import { finalBidSchema } from "validations";
import { getBidsByStatus, updateBid } from "store/bid/bidActions";
import { toast } from "react-toastify";
import uploadFileToS3 from "utils/s3Upload";

// Images



import WatchGrayIcon from "assets/svgs/WatchGray.svg";
import { toCamelCase } from "utils";
import BidInformation from "./card";

const BidInProcessCard = ({
  type,
  site,
  bidId,
  bid,
  customer,
  customerInfo,
  cost,
  status,
}) => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.bid);
  const [finalQuotePopupOpen, setFinalQuotePopupOpen] = useState(false);
  const [timePopupOpen, seTimePopupOpen] = useState(false);
  const [timePopupOpen2, seTimePopupOpen2] = useState(false);
  const [fileList, setFileList] = useState([]);

  const handleFinalQuotePopupClose = () => {
    setFinalQuotePopupOpen(false);
  };

  useEffect(() => {
    if (site?.technologies) {
      site.technologies.forEach((tech) => {
        const camelCasedTechType = toCamelCase(tech.technologyType);
        setValue(camelCasedTechType, tech.capacity);
      });
    }
  }, [site?.technologies]);

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
    watch,
  } = useForm({ resolver: yupResolver(finalBidSchema) });

  const [siteVisitMorning, setSiteVisitMorning] = useState();
  const [siteVisitEvening, setSiteVisitEvening] = useState();
  const [morningTime, setMorningTime] = useState("morning");
  const [eveningTime, setEveningTime] = useState("");
  const [error, setError] = useState("");

  const onMorningTimeChange = (date) => {
    setSiteVisitMorning(date);
  };
  const onEveningTimeChange = (date) => {
    setSiteVisitEvening(date);
  };

  const onClickScheduleSite = () => {
    if (
      !siteVisitEvening ||
      !siteVisitMorning ||
      !eveningTime ||
      !morningTime
    ) {
      setError("Please select time to visit");
    } else {
      setError("");
      const payload = {
        id: bidId,
        data: {
          siteVisitOne: {
            date: siteVisitEvening,
            time: eveningTime,
          },
          siteVisitTwo: {
            date: siteVisitMorning,
            time: morningTime,
          },
          status: "scheduled",
        },
      };
      // fetch bids only based on the type
      dispatch(updateBid(payload))
        .unwrap()
        .then((res) => {
          toast.success("Bid Updated Successfully!", { toastId: "schedule" });
          dispatch(getBidsByStatus("shortListed,scheduled"));
        })
        .catch((err) => {
          toast.error(err || "SOmething went wrong!", {
            toastId: "scheduleError",
          });
        });
    }
  };

  // submit final bid
  const onSubmitFinalBid = async (data) => {
    const {
      materialCost,
      laborCost,
      additionalCost,
      totalCost,
      ...technologyData
    } = data;

    const technologies = Object.keys(technologyData).map((key) => ({
      technologyType: key,
      capacity: technologyData[key],
    }));

    const uploadedFiles = await Promise.all(
      fileList.map(async (file) => {
        const url = await uploadFileToS3(file);
        return { url: url.url, name: file.name };
      })
    );

    const payload = {
      id: bid._id,
      data: {
        status: "finalBidSubmitted",
        cost: {
          initialBid: {
            materialCost: bid.cost.initialBid.materialCost,
            laborCost: bid.cost.initialBid.laborCost,
            additionalCost: bid.cost.initialBid.additionalCost,
            totalCost: bid.cost.initialBid.totalCost,
            attachments: bid.cost.initialBid.attachments || [],
          },
          finalBid: {
            materialCost: Number(materialCost),
            laborCost: Number(laborCost),
            additionalCost: Number(additionalCost),
            totalCost:
              Number(materialCost) + Number(laborCost) + Number(additionalCost),
            attachments: uploadedFiles || [],
          },
        },
        technologies,
      },
    };

    dispatch(updateBid(payload))
      .unwrap()
      .then((res) => {
        setFinalQuotePopupOpen(false);
        reset();
        dispatch(getBidsByStatus("scheduled"));
        toast.success("Final quote submitted successfully!", {
          toastId: "finalQuote",
        });
      })
      .catch((err) => {
        toast.error(err || "Something went wrong", {
          toastId: "finalQuoteError",
        });
      });
  };

  return (
    <div className="max-w-[840px] bg-white flex-column gap-2 px-2 md:px-4 xl:px-6 py-4 md:py-6 xl:py-8 rounded-xl shadow-md">
      <BidInformation
        customerInfo={customerInfo}
        site={site}
        bid={bid}
        type={type}
        cost={cost}
        customer={customer}
      />
      {type === "scheduleVisit" && status === "shortListed" ? (
        <div className="flex flex-wrap gap-4 md:gap-8 mt-4">
          {error ? (
            <div className="flex items-center w-full">
              <Alert message={error} type="error" showIcon />
            </div>
          ) : null}
          <div className="relative flex-column gap-1">
            <p className="text-sm md:text-base text-gray3 font-[400]">
              Select Site Visit Time 1
            </p>
            <div className="row-flex gap-2 items-center">
              <ConfigProvider
                theme={{
                  token: {
                    colorPrimary: "#8DC63F",
                  },
                }}
              >
                <DatePicker
                  className="custom-date-picker"
                  // value={moment(siteVisitMorning)}
                  onChange={onMorningTimeChange}
                  disabledDate={(current) => {
                    return (
                      moment().add(-1, "days") >= current ||
                      moment().add(1, "month") <= current
                    );
                  }}
                />
              </ConfigProvider>
              <div
                className="w-[150px] border-[1px] h-[33px] border-gray rounded-md flex flex-row items-center justify-between px-4  gap-2 cursor-pointer"
                onClick={() => seTimePopupOpen(!timePopupOpen)}
              >
                <p
                  className={`font-[300] capitalize ${
                    morningTime
                      ? "text-[#000000] text-sm"
                      : "text-[#bfbfbf] text-[13px] "
                  }`}
                >
                  {morningTime ? morningTime : "Choose Time"}
                </p>
                <img src={WatchGrayIcon} alt="icon" />
              </div>
              {timePopupOpen ? (
                <TimePopup
                  setPopupOpen={seTimePopupOpen}
                  setTime={setMorningTime}
                  value={morningTime}
                />
              ) : null}
            </div>
          </div>
          <div className="relative flex-column gap-1">
            <p className="text-sm md:text-base text-gray3 font-[400]">
              Select Site Visit Time 2
            </p>
            <div className="row-flex gap-2 items-center">
              <ConfigProvider
                theme={{
                  token: {
                    colorPrimary: "#8DC63F",
                  },
                }}
              >
                <DatePicker
                  className="custom-date-picker"
                  onChange={onEveningTimeChange}
                  disabledDate={(current) => {
                    return (
                      moment().add(-1, "days") >= current ||
                      moment().add(1, "month") <= current
                    );
                  }}
                />
              </ConfigProvider>
              <div
                className="w-[150px] border-[1px] h-[33px] border-gray rounded-md flex flex-row items-center justify-between px-4 gap-2 cursor-pointer"
                onClick={() => seTimePopupOpen2(!timePopupOpen2)}
              >
                <p
                  className={`font-[300] capitalize ${
                    eveningTime
                      ? "text-[#000000] text-sm"
                      : "text-[#bfbfbf] text-[13px] "
                  }`}
                >
                  {eveningTime ? eveningTime : "Choose Time"}
                </p>
                <img src={WatchGrayIcon} alt="icon" />
              </div>
            </div>
            {timePopupOpen2 ? (
              <TimePopup
                setPopupOpen={seTimePopupOpen2}
                setTime={setEveningTime}
                value={eveningTime}
              />
            ) : null}
          </div>
        </div>
      ) : null}
      {type === "scheduleVisit" && status === "shortListed" ? (
        <Button
          variant="bordered"
          text="Schedule Site Visit"
          className="ml-auto mt-6"
          loading={loading}
          onClick={onClickScheduleSite}
        />
      ) : type === "confirmedVisit" && status === "scheduledConfirmed" ? (
        <Button
          variant="bordered"
          text="Submit Final Quote"
          className="ml-auto mt-6"
          onClick={() => setFinalQuotePopupOpen(true)}
        />
      ) : type === "confirmedVisit" && status === "finalBidSubmitted" ? (
        <span className="text-primary italic font-semibold ml-auto">
          Final Bid Submitted
        </span>
      ) : null}
      <FinalQuotePopup
        open={finalQuotePopupOpen}
        handleClose={handleFinalQuotePopupClose}
        control={control}
        handleSubmit={handleSubmit}
        onSubmit={onSubmitFinalBid}
        setValue={setValue}
        errors={errors}
        technologies={site.technologies}
        fileList={fileList}
        setFileList={setFileList}
        loading={loading}
        watch={watch}
      />
    </div>
  );
};

export { BidInProcessCard };
