import React, { useRef, useEffect } from "react";
import { useOnClickOutside } from "hooks/useOnClickOutside";

const Modal = ({ open, handleClose, children, className }) => {
  const ref = useRef();
  useOnClickOutside(ref, () => handleClose());

  useEffect(() => {
    // 27 is the key code for escape
    function handleKeyDown(event) {
      if (event.keyCode === 27) {
        handleClose();
      }
    }
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {open && (
        <div
          className="w-screen h-screen z-50 right-0 top-0 fixed shadow flex justify-center items-center m-0"
          style={{
            backgroundColor: "rgba(0,0,0,0.8)",
          }}
        >
          <div
            className={`relative bg-white rounded-[5px] shadow-md p-4 md:p-8 ${className}`}
            ref={ref}
          >
            {children}
          </div>
        </div>
      )}
    </>
  );
};
export { Modal };
