import React, { useEffect, useState } from 'react';
import { InstallerInviteCard, ProjectDetails } from 'components/customer';
import { ProjectInfo } from 'components/macros';

// Images
import GridIcon from 'assets/svgs/Grid.svg';
import { useDispatch, useSelector } from 'react-redux';
import { getSitesByCustomerId } from 'store/sites/sitesActions';
import { PageLoader } from 'components/elements';
import { getInvitesForCustomer } from 'store/invites/invitesAction';
import { useNavigate } from 'react-router-dom';
import ContactInfoPopup from 'components/customer/ContactDetailPopup';

const BidRequests = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, sites } = useSelector((state) => state.sites);
  const { loading: invitesLoading, invites } = useSelector(
    (state) => state.invite
  );

  const customerId = localStorage.getItem('customerId');

  const [selectedSite, setSelectedSite] = useState(sites ? sites[0] : {});

  useEffect(() => {
    dispatch(getSitesByCustomerId(customerId));
  }, [dispatch, customerId]);

  useEffect(() => {
    if (sites.length) {
      setSelectedSite(sites ? sites[0] : {});
    }
  }, [sites]);

  useEffect(() => {
    dispatch(
      getInvitesForCustomer({
        siteId: selectedSite?._id,
        customerId: customerId,
      })
    )
      .unwrap()
      .then(() => {
        getSitesByCustomerId(customerId);
      }).catch((err) => {
      })
  }, [selectedSite?._id, dispatch, customerId]);

  return loading ? (
    <div className='w-full h-[90vh] m-auto flex items-center justify-center'>
      <PageLoader />
    </div>
  ) : sites?.length === 0 ? (
    <p>No Sites found!</p>
  ) : (
    <div className='relative flex-column md:row-flex gap-8 w-full  py-4 md:py-8 px-1'>
      <div className='order-last md:order-first flex-column gap-8'>
        <ProjectDetails showMatchCheck={false} site={selectedSite} />
        {invitesLoading ? (
          <div className='w-full h-[5rem] grid place-items-center'>
            <PageLoader />
          </div>
        ) : (
          <div className='flex flex-wrap gap-8'>
            {invites?.length > 0 ? (
              invites?.map((bid) => {
                return (
                  <>
                    <InstallerInviteCard
                      key={bid?._id}
                      installer={bid?.installerId}
                      bid={bid._id}
                      loading={invitesLoading}
                      technologies={bid?.installerId?.technologies || []}
                    />
                  </>
                );
              })
            ) : (
              <div className='flex flex-col items-start gap-2'>
                <p>
                  No bid requests found for your site. Why not invite installers
                  to bid for your project.
                </p>
                <button
                  className='text-primary'
                  onClick={() => navigate('/dashboard/sentInvites')}
                >
                  Invite Installers
                </button>
              </div>
            )}
          </div>
        )}
      </div>

      <div className='flex-column gap-2'>
        <div className='row-flex items-center gap-2'>
          <div className='w-10 h-10 rounded-full grid place-items-center bg-[#F9FFF1]'>
            <img src={GridIcon} alt='grid' />
          </div>
          <p className='text-sm md:text-lg text-black'>Projects</p>
        </div>
        {sites?.length > 0 ? (
          sites?.map((site) => (
            <ProjectInfo
              site={site}
              selected={site?._id === selectedSite?._id}
              onClick={() => setSelectedSite(site)}
              key={site._id}
            />
          ))
        ) : (
          <p>No Projects available!</p>
        )}
      </div>

    </div>

  );
};

export { BidRequests };
