import { ProjectCompletedDate } from "components/customer";
import { PageLoader } from "components/elements";
import NoDataPlaceholder from "components/global/NoDataFound";
import { BidCard } from "components/installer";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const ProjectsStarted = () => {
  const { id } = useParams();
  const { loading, startedProjects } = useSelector((state) => state.bid);

  const selectedProject = useMemo(
    () => startedProjects.find((item) => item._id === id),
    [id, startedProjects]
  );

  if (loading) {
    return (
      <div className="w-full h-[90vh] m-auto flex items-center justify-center">
        <PageLoader />
      </div>
    );
  }

  if (!selectedProject && !loading) {
    return <NoDataPlaceholder />;
  }

  return (
    <div className="flex-column relative gap-8 w-full h-full py-4 md:py-8 px-1">
      <BidCard
        type="projectStarted"
        site={selectedProject?.siteId}
        finalBid={selectedProject?.cost?.finalBid}
        customer={selectedProject?.customerId}
        status={selectedProject?.status}
        technologies={selectedProject?.technologies}
        bid={selectedProject}
      />

      <ProjectCompletedDate
        userRole="installer"
        projectStartedDate={selectedProject?.project.projectStartedDate}
        projectCompletedDate={selectedProject?.project.projectEndDate}
        isCompleted={selectedProject?.project?.isCompleted}
      />
    </div>
  );
};

export { ProjectsStarted };
