import { PageLoader } from "components/elements";
import { BidCard } from "components/installer";
import { INSTALLER_DATA } from "mock/data";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAcceptedInvitesForInstaller } from "store/invites/invitesAction";

const Invitations = () => {
  const dispatch = useDispatch();
  const { loading, invites } = useSelector((state) => state.invite);

  const installerId = localStorage.getItem("installerId");

  useEffect(() => {
    dispatch(getAcceptedInvitesForInstaller(installerId));
  }, [dispatch, installerId]);

  return (
    <>
      <div className="flex flex-col gap-2 py-4 text-darkGray2">
        <p className="text-base font-medium">
          {INSTALLER_DATA.invitations["mainText"]}
        </p>
        <p>{INSTALLER_DATA.invitations["helperText"]}</p>
      </div>
      {loading ? (
        <div className="w-full h-[90vh] m-auto flex items-center justify-center">
          <PageLoader />
        </div>
      ) : invites?.length === 0 ? (
        <p>No invitations available!</p>
      ) : (
        <div className="flex-column gap-8 w-full h-full py-4 md:py-8 px-2">
          {invites?.map((item) => (
            <BidCard
              customer={item?.customerId}
              type="invitation"
              key={item._id}
              site={item?.siteId}
            />
          ))}
        </div>
      )}
    </>
  );
};

export { Invitations };
