import 'animate.css';
import 'antd.css';
import router from 'router/browserRouter';
import { RouterProvider } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from 'store';
import { SocketProvider } from 'context/socket';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-international-phone/style.css';


function App() {
  return (
    <>
      <Provider store={store}>
        <SocketProvider>
          <RouterProvider router={router} />
          <ToastContainer
            toastClassName={() =>
              'text-[#121212] bg-white shadow-lg relative font-medium flex px-2 py-3 min-h-10 rounded-md items-center justify-between overflow-hidden cursor-pointer font-Poppins '
            }
          />
        </SocketProvider>
      </Provider>
    </>
  );
}

export default App;
