import React, { useEffect } from 'react';
import { DashboardCard } from 'components/macros';
import { Link, useNavigate } from 'react-router-dom';
import { PageLoader } from 'components/elements';
import { useDispatch, useSelector } from 'react-redux';
import { getDashboardDataCustomer } from 'store/customer/customerActions';

// Images
import DarkGreen from 'assets/svgs/dashboard/DarkGreen.svg';
import LightGreen from 'assets/svgs/dashboard/LightGreen.svg';
import Purple from 'assets/svgs/dashboard/Purple.svg';
import Blue from 'assets/svgs/dashboard/Blue.svg';
import { verifyToken } from 'store/auth/authActions';
import { CUSTOMER_DATA } from 'mock/data';

const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, dashboardData } = useSelector((state) => state.customer);
  const { shortListedSites: sites } = useSelector((state) => state.sites);
  const { startedProjects } = useSelector((state) => state.bid);
  // const [isValid,]

  useEffect(() => {
    dispatch(getDashboardDataCustomer());
  }, [dispatch]);

  const CUSTOMER_DASHBOARD_GET_STARTED = [
    {
      id: 1,
      title: 'Send Invites to Applicable Installers',
      count: dashboardData?.sentInvites,
      btnText: 'Check Send Invites',
      color: '#048583',
      image: DarkGreen,
      className: 'invitations text-[#048583] group-hover:text-white',
      route: '/dashboard/sentInvites',
    },
    {
      id: 2,
      title: 'Bid Requests from Applicable Installers',
      count: dashboardData?.bidRequests,
      btnText: 'Check Bid Requests',
      color: '#50CD89',
      image: LightGreen,
      className: 'opportunities text-[#50CD89] group-hover:text-white',
      route: '/dashboard/bidRequests',
    },
  ];

  const CUSTOMER_DASHBOARD_BID_IN_PROCESS = [
    {
      id: 3,
      title: 'Projects Pending',
      count: dashboardData?.projects,
      btnText: 'See Details',
      color: '#415BE3',
      image: Blue,
      className: 'bid-in-review text-[#415BE3] group-hover:text-white',
      route: `/dashboard/customer/projects/${sites[0]?._id}`,
    },
    {
      id: 4,
      title: 'Projects Started',
      count: dashboardData?.projectStarted,
      btnText: 'See Details',
      color: '#BE5CFB',
      image: Purple,
      className: 'schedule-site-visit text-[#BE5CFB] group-hover:text-white',
      route: `/dashboard/customer/projectsStarted/${startedProjects[0]?._id}`,
    },
  ];

  return loading ? (
    <div className='w-full h-[90vh] m-auto flex items-center justify-center'>
      <PageLoader />
    </div>
  ) : (
    <div className='flex-column gap-8 py-8'>
      <div className='flex-column gap-4'>
        <div className='flex flex-col gap-2 pb-4 text-darkGray2'>
          <p className='text-base font-medium'>
            {CUSTOMER_DATA.dashboard['mainText']}
          </p>
          <ul className='list-disc ml-8'>
            {CUSTOMER_DATA.dashboard['points'].map((item, i) => (
              <li key={'points' + 1}>
                {item.text}{' '}
                {item.href && (
                  <span>
                    <Link to={item.href} className='text-primary no-underline'>
                      ({item.linkText})
                    </Link>
                  </span>
                )}
              </li>
            ))}
          </ul>
          <p>{CUSTOMER_DATA.dashboard['helperText']}</p>
        </div>
        <p className='text-lg font-[500] text-black'>Get Started</p>
        <div className='flex flex-wrap gap-8'>
          {CUSTOMER_DASHBOARD_GET_STARTED.map((item) => (
            <DashboardCard
              key={item.id}
              title={item.title}
              color={item.color}
              count={item.count}
              image={item.image}
              btnText={item.btnText}
              className={item?.className}
              onClick={() => navigate(item.route)}
            />
          ))}
        </div>
      </div>
      <div className='flex-column gap-4'>
        <p className='text-lg font-[500] text-black'>Projects</p>
        <div className='flex flex-wrap gap-8'>
          {CUSTOMER_DASHBOARD_BID_IN_PROCESS.map((item) => (
            <DashboardCard
              key={item.id}
              title={item.title}
              color={item.color}
              count={item.count}
              image={item.image}
              btnText={item.btnText}
              className={item?.className}
              onClick={() => navigate(item.route)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export { Dashboard };
