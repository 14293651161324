import React, { useEffect, useState } from 'react';
import { SideBarItem } from 'components/macros';
import Logo from 'assets/svgs/Logo.svg';
import LogoSmall from 'assets/svgs/LogoSmall.svg';
import Logout from 'assets/svgs/Logout.svg';
import Avatar from 'assets/svgs/Avatar.svg';
import { useDispatch, useSelector } from 'react-redux';
import { installerLogout } from 'store/auth/authActions';
import { useNavigate } from 'react-router-dom';
import { getInstallerById } from 'store/installer/installerActions';
import { UserOutlined } from '@ant-design/icons';
import { Avatar as UserAvatar } from 'antd';

function SideBar({ routes }) {
  const { user } = useSelector((state) => state.installer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const role = localStorage.getItem('user_role');
  const [customerName, setCustomerName] = useState('');
  const customer_name = localStorage.getItem('customer_name') || 'User';
  const companyName = localStorage.getItem('customer_company_name') || ''


  const ProfileNavigate = () => {
    if (role === 'installer') {
      navigate('/dashboard/profile');
    }
  };

  const userId = localStorage.getItem('user_id');
  useEffect(() => {
    if (role === 'installer' && userId) {
      dispatch(getInstallerById(userId));
    }
  }, [dispatch, userId, role]);

  useEffect(() => {
    if (customer_name && typeof customer_name === 'string') {
      const spitedName = customer_name.split('.');
      if (spitedName.length >= 2) {
        setCustomerName(spitedName[0] + ' ' + spitedName[1]);
      } else {
        setCustomerName(customer_name);
      }
    } else {
      setCustomerName('');
    }
  }, [customer_name]);

  const handleLogout = () => {
    dispatch(installerLogout());
  };

  return (
    <div
      className={`h-screen sticky top-0 gap-[24px] justify-between flex-column items-center
      xl:min-w-[280px] w-[80px] max-w-[280px] shadow-dark pt-[32px] px-[17px]`}
    >
      <div className='w-full overflow-auto'>
        <img src={Logo} className='hidden xl:block w-[75%] m-auto' alt='Logo' />
        <img
          src={LogoSmall}
          className='xl:hidden w-[100px] h-[100px] m-auto'
          alt='Logo'
        />
        <div className='flex-column mt-[32px] w-full gap-2 text-center justify-center items-center'>
          {routes.map((current, index) => {
            return <SideBarItem item={current} key={index} />;
          })}
        </div>
      </div>
      <div className='flex-column gap-2 w-full'>
        <div className='flex-column gap-2'>
          {/* {BOTTOM_ROUTES.map((item) => (
            <SideBarItem item={item} key={item.key} />
          ))} */}
        </div>

        <div className='py-4 bg-white px-[14px] flex justify-between items-center border-t-2 border-gray-600 w-full'>
          <span className='flex gap-3 cursor-pointer' onClick={ProfileNavigate}>
            {role === 'customer' ? (
              <UserAvatar
                style={{
                  backgroundColor: '#87d068',
                }}
                icon={<UserOutlined />}
              />
            ) : user?.profileImage ? (
              <img
                src={user?.profileImage}
                alt='icon'
                className='w-8 h-8 rounded-full'
              />
            ) : (
              <UserAvatar
                style={{
                  backgroundColor: '#87d068',
                }}
                icon={<UserOutlined />}
              />
            )}

            <div className='flex justify-center flex-col'>
              <span className='hidden text-sm text-darkBlue xl:flex items-center font-bold capitalize'>
                {role === 'customer' ? customerName : user?.name ? user.name : ''}
              </span>
              <p className='font-[500] text-sm text-darkBlue'>{companyName}</p>
            </div>
          </span>
          <img
            src={Logout}
            className='hidden xl:block cursor-pointer'
            alt='icon'
            onClick={handleLogout}
          />
        </div>
      </div>
    </div>
  );
}

export { SideBar };
