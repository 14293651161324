import React, { useEffect, useState } from "react";
import { Address, RegionsList, TechnologiesList } from "components/macros";
import { ProfileBanner } from "components/macros";
import { useDispatch, useSelector } from "react-redux";
import { getInstallerById } from "store/installer/installerActions";
import { PageLoader } from "components/elements";
import { useParams } from "react-router-dom";
import FileLists from 'components/macros/FileLists';

function Profile() {
  const dispatch = useDispatch();
  const { loading, installers: installer } = useSelector((state) => state.installer);


  const [profileImage, setProfileImage] = useState();
  const { id } = useParams();

  useEffect(() => {
    const userId = localStorage.getItem("user_id");
    if (id) {
      dispatch(getInstallerById(id))
        .unwrap()
        .then((res) =>
          setProfileImage({
            preview: res?.profileImage,
          })
        );
    } else {
      dispatch(getInstallerById(userId))
        .unwrap()
        .then((res) =>
          setProfileImage({
            preview: res?.profileImage,
          })
        );
    }
  }, [id, dispatch]);

  if (loading) {
    return (
      <div className="w-full h-[90vh] m-auto flex items-center justify-center">
        <PageLoader />
      </div>
    );
  }

  return (
    <div className="h-[90vh] flex-column gap-[50px] overflow-auto">
      <ProfileBanner
        loading={loading}
        profileImage={profileImage}
        mode="view"
      />
      <div className="w-full lg:w-[80%] lg:pl-[50px] flex flex-col gap-[30px] space-y-4">
        <Address />
        <div className="flex flex-col gap-[17px]">
          <div>
            <p className="text-[#98A2B3] font-bold text-[15px]'">
              Company Description
            </p>
            <p className="text-[#98A2B3] font-[400] text-[15px]">
              Description about organization
            </p>
          </div>
          <span className="inline-flex gap-[10px]">
            <p className="text-sm md:text-base text-[#5E6278] font-[500]">
              {installer?.description}
            </p>
          </span>
        </div>
        <div className="flex flex-col gap-[17px]">
          <div>
            <p className="text-[#98A2B3] font-bold text-[15px]">Attachments</p>
            <p className="text-[15px] font-[400] text-[#98A2B3]">
              Relevant documents about organization
            </p>
          </div>
          <FileLists fileList={installer?.extras?.images || []} />
        </div>
        <TechnologiesList
          type="profile"
          technologies={installer?.technologies || []}
        />
        <RegionsList />
      </div>
    </div>
  );
}

export { Profile };
