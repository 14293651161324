import { ProfileBanner } from 'components/macros'
import { ProfileForm } from 'components/installer'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { getInstallerById, updateInstallerProfile } from 'store/installer/installerActions'
import { TECHNOLOGIES } from 'mock'
import { profileSchema } from 'validations'
import { yupResolver } from "@hookform/resolvers/yup";
import { PageLoader } from "components/elements";
import { useNavigate } from 'react-router-dom'

function EditProfile() {
  const [profileImage, setProfileImage] = useState(null)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [fileList, setFileList] = useState([]);
  const { loading: loader } = useSelector(state => state.installer)

  const { control, watch, reset, handleSubmit, getValues, setValue,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(profileSchema),
  })
  const [techs, setTechs] = useState(TECHNOLOGIES)
  const [loading, setLoading] = useState(true)


  useEffect(() => {
    const id = localStorage.getItem('user_id');
    dispatch(getInstallerById(id))
      .unwrap()
      .then(res => {

        if (res && res.region) {
          reset(res);
          setProfileImage({
            preview: res?.profileImage
          })
          if (res && res?.extras?.images?.length > 0) {
            const images = [...res.extras.images]

            setFileList(images)
          }
          setLoading(false)
        }
      }).catch(err => setLoading(false))
  }, [dispatch, reset]);





  const navigateToEdit = () => {
    setTimeout(() => {
      navigate('/dashboard/profile')
    }, 2000)
  }


  const onSubmit = (data) => {
    const updatedData = {
      ...data,
      extras: {
        images: fileList.length > 0 ? [...fileList] : []
      },
      image: profileImage?.file ? profileImage.file : null
    }
    dispatch(updateInstallerProfile({
      ...updatedData
    }))
      .unwrap()
      .then(() => {
        navigateToEdit()
      })

  }

  if (loading) {
    return (
      <div className="w-full h-[90vh] m-auto flex items-center justify-center">
        <PageLoader />
      </div>
    )
  }


  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ProfileBanner loading={loader}
        setProfileImage={setProfileImage}
        profileImage={profileImage} mode="edit" />

      <ProfileForm watch={watch} fileList={fileList}
        setFileList={setFileList} loading={loader} mode="edit"
        errors={errors} getValues={getValues}
        setValue={setValue} techs={techs}
        setTechs={setTechs} control={control} />
    </form>
  )
}

export { EditProfile }
