import React, { useEffect, useState } from 'react';
import {
  FinalBidSubmitted,
  InitialBid,
  ProjectDetails,
  SiteVisitDetails,
} from 'components/customer';
import { InstallerInfo } from 'components/macros';
// Images
import GridIcon from 'assets/svgs/Grid.svg';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { PageLoader } from 'components/elements';
import { getBidsBySiteIdInCustomer } from 'store/bid/bidActions';
const Projects = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { loading, bids, companyName } = useSelector((state) => state.bid);

  const [selectedBid, setSelectedBid] = useState({});

  useEffect(() => {
    if (id) {
      dispatch(getBidsBySiteIdInCustomer(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    setSelectedBid(bids[0]);
  }, [bids]);



  const navigate = useNavigate();

  return (
    <>
      <div className="flex flex-col gap-2 pb-4 text-darkGray2">
        <p className="text-base font-medium">
          The project details for all pending projects can be found here. You
          can shortlist initial bids, schedule site visits with potential
          installers and accept final bids.
        </p>
      </div>
      {loading ? (
        <div className="w-full h-[90vh] m-auto flex items-center justify-center">
          <PageLoader />
        </div>
      ) : bids?.length === 0 ? (
        <div className="flex flex-col items-start gap-2 py-8">
          <p className="text-darkGray2 text-base font-medium">
            No bid found for this site. Check the bid requests for this project
            or send an invite to installer to help progress this project.
          </p>
          <div className="flex flex-row gap-8">
            <button
              className="text-primary"
              onClick={() => navigate("/dashboard/sentInvites")}
            >
              Invite Installers
            </button>
            <button
              className="text-primary"
              onClick={() => navigate("/dashboard/bidRequests")}
            >
              Check Bid Requests
            </button>
          </div>
        </div>
      ) : (
        <div className="flex-column xl:row-flex gap-8 w-full h-full">
          <div className="relative order-last xl:order-first py-4 md:py-8 px-1">
            <div className="relative z-[1] flex-column gap-8">
              {selectedBid?.status === "processing" ? (
                <>
                  <ProjectDetails
                    showMatchCheck
                    companyName={companyName}
                    site={selectedBid?.siteId}
                    selectedProject={selectedBid}
                  />
                  <InitialBid bid={selectedBid} />
                </>
              ) : null}
              {selectedBid?.status === "shortListed" ? (
                <>
                  <ProjectDetails
                    showMatchCheck
                    companyName={companyName}
                    site={selectedBid?.siteId}
                    selectedProject={selectedBid}
                  />
                  <InitialBid bid={selectedBid} />
                </>
              ) : null}
              {selectedBid?.status === "scheduled" ||
              selectedBid?.status === "scheduledConfirmed" ? (
                <>
                  <ProjectDetails
                    showMatchCheck
                    companyName={companyName}
                    site={selectedBid?.siteId}
                    selectedProject={selectedBid}
                  />
                  <InitialBid bid={selectedBid} />
                  <SiteVisitDetails bid={selectedBid} />
                </>
              ) : null}
              {selectedBid?.status === "finalBidSubmitted" ? (
                <>
                  <ProjectDetails
                    showMatchCheck
                    companyName={companyName}
                    site={selectedBid?.siteId}
                    selectedProject={selectedBid}
                  />
                  <InitialBid bid={selectedBid} />
                  <SiteVisitDetails bid={selectedBid} />
                  <FinalBidSubmitted bid={selectedBid} />
                </>
              ) : null}
            </div>

            {/* Vertical Green Line */}
            <div className="absolute top-2 left-[38px] z-[0] w-[1px] h-[80%] bg-primary">
              <div className="absolute top-0 left-1/2 transform -translate-x-1/2">
                <div className="w-2 h-2 rounded-full bg-primary"></div>
              </div>
            </div>
          </div>
          <div className="flex-column gap-2  py-4 md:py-8 px-1">
            <div className="row-flex items-center gap-2">
              <div className="w-10 h-10 rounded-full grid place-items-center bg-[#F9FFF1]">
                <img src={GridIcon} alt="grid" />
              </div>
              <p className="text-sm md:text-lg text-black">Installers</p>
            </div>
            {bids?.map((bid) => (
              <InstallerInfo
                key={bid._id}
                selected={selectedBid?._id === bid?._id}
                data={bid?.installerId}
                onClick={() => setSelectedBid(bid)}
                loading={loading}
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export { Projects };
