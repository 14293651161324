import { CREATE_ACCOUNT_STEPS } from 'mock'
import Logo from 'assets/svgs/Logo.svg'
import Check from 'assets/svgs/Check.svg'

const CreateAccountSteps = ({ step }) => {
  return (
    <div className="w-full h-full flex-column items-center justify-center gap-8 py-8 lg:py-4 xl:py-8">
      <img src={Logo} alt="company-logo" className="w-[200px] h-[70px]" />
      <div className="flex flex-col gap-4 sm:gap-8 lg:gap-6 1420:gap-12">
        {CREATE_ACCOUNT_STEPS.map(({ title, breif }, index) => (
          <div className="flex gap-2 items-center" key={index}>
            <div className="w-[40px] h-[40px] 1420:h-[50px] 1420:w-[50px] bg-secondary  flex items-center justify-center rounded-full">
              <p className="text-primaryDark font-semibold text-lg">
                {step > index + 1 ? (
                  <img src={Check} alt="check-mark" />
                ) : (
                  index + 1
                )}
              </p>
            </div>

            <div
              className={`flex-column font-medium ${
                step > index + 1 ? 'opacity-25' : ''
              }`}
            >
              <p className="text-sm 1420:text-lg">{title}</p>
              <p className="text-xs 1420:text-sm text-darkGray"> {breif}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export { CreateAccountSteps }
