import React, { useState, useRef } from "react";
import AddIcon from "assets/svgs/AddIcon.svg";
import SendIcon from "assets/svgs/SendIcon.svg";
import SmileIcon from "assets/svgs/SmileIcon.svg";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { useOnClickOutside } from "react-calendar-toolkit";
import Cross from "assets/svgs/Cross.svg";
import File from "assets/svgs/File.svg";
import { useSelector } from "react-redux";
import { Loader } from "components/elements";

const PreviewComponent = ({ files, setFiles }) => {
  const handleRemoveFile = (index) => {
    setFiles((prevFiles) => {
      const updatedFiles = [...prevFiles];
      updatedFiles.splice(index, 1);
      return updatedFiles;
    });
  };

  return (
    <div className="flex overflow-auto p-3 ml-[18px] gap-3">
      {files?.map((current, index) => {
        const fileName = current.file.name;
        const fileExtension = fileName?.substr(fileName.lastIndexOf(".") + 1);
        const displayedFileName =
          fileName?.length > 15
            ? fileName?.substring(0, 15) + ".. ." + fileExtension
            : fileName;
        const size = (current.file.size / 1024).toFixed(2) + " Mb";
        const fileType = current.file.type;


        if (fileType.includes("image")) {
          return (
            <div className="flex relative  items-center rounded-md bg-lightGreen p-1 gap-3" key={index}>
              <div className="relative">
                <img
                  className="min-w-[55px] max-w-[55px] h-[55px] rounded-xl"
                  src={current.preview}
                  alt={`File Preview ${index}`}
                />
                <img
                  src={Cross}
                  onClick={() => {
                    handleRemoveFile(index);
                  }}
                  className="w-[16px] cursor-pointer absolute -top-3 -right-2"
                  alt="Delete"
                />
              </div>
            </div>
          );
        } else {
          return (
            <div className="bg-lightGreen w-full flex items-center min-w-[200px] max-w-[200px] p-2 break-words rounded-md relative" key={index}>
              <div className="flex items-center gap-1 w-full">
                <img
                  className="w-[32px] h-[32px] rounded-xl"
                  src={File}
                  alt={`File Preview ${index}`}
                />
                <div className="flex-column">
                  <span className="font-bold text-xs text-black">
                    {displayedFileName}
                  </span>
                  <span className="text-[10px] text-darkGray">{size}</span>
                </div>
              </div>
              <img
                src={Cross}
                onClick={() => {
                  handleRemoveFile(index);
                }}
                className="w-[16px] cursor-pointer absolute -top-2 -right-0"
                alt="Delete"
              />
            </div>
          );
        }
      })}
    </div>
  );
};


const FileInputComponent = ({ handleFileChange }) => {
  const handleChange = (event) => {
    const files = [...event.target.files];
    handleFileChange(files);
  };

  return (
    <div className="m-auto">
      <input
        type="file"
        accept="*"
        className="hidden"
        id="fileInput"
        onChange={handleChange}
        multiple
      />
      <label htmlFor="fileInput">
        <img
          src={AddIcon}
          className="min-w-[40px] cursor-pointer"
          alt="Add Icon"
        />
      </label>
    </div>
  );
};

function TextArea({ setFiles, files, handleMessageSend }) {
  const [showEmojiPicker, setEmojiPicker] = useState(false);
  const [message, setMessage] = useState("");
  const { addMessageLoader } = useSelector(state => state.chat)



  const selectEmoji = (emoji) => {
    if (message.length) {
      setMessage(message + emoji.native);
    } else {
      setMessage(emoji.native);
    }
  };

  const handleShowEmojiPicker = () => {
    setEmojiPicker(!showEmojiPicker);
  };

  const emojiRef = useRef();
  useOnClickOutside(emojiRef, () => setEmojiPicker(!showEmojiPicker));


  const handleTextSend = () => {
    handleMessageSend(message)
    setMessage('')
  }

  const handleFileChange = (newFiles) => {
    if (newFiles.length < 10 && files.length < 10) {
      const remainingFilesCount = 10 - newFiles.length;
      const filesToAdd = newFiles.slice(0, remainingFilesCount);
      filesToAdd.forEach((file) => {
        const reader = new FileReader();
        reader.onload = () => {
          const filePreview = reader.result;
          const fileWithPreview = {
            file,
            preview: filePreview,
          };

          setFiles((prevFiles) => [...prevFiles, fileWithPreview]);
        };

        reader.readAsDataURL(file);
      });
    }
  };




  return (
    <div className="bg-[white]">
      <PreviewComponent files={files} setFiles={setFiles} />
      <div className="row-flex px-4 relative w-full m-auto  justify-between item-center gap-3">
        <FileInputComponent handleFileChange={handleFileChange} />
        <textarea
          onChange={(e) => setMessage(e.target.value)}
          value={message}
          placeholder="Type a message here"
          className="chat-textBox text-darkBlue w-[85%]"
          type=""
        />
        <div className="row-flex gap-8">
          <img
            src={SmileIcon}
            onClick={handleShowEmojiPicker}
            className="max-w-[24px] cursor-pointer"
          />
          {addMessageLoader ? <div className="-mr-2 rounded-full mt-3 items-center flex justify-center w-[40px] h-[40px] bg-primary"> <Loader /> </div> : <img onClick={handleTextSend} src={SendIcon} className="w-10 cursor-pointer" />}
          <span className="absolute right-[50px] z-1 top-3">
            {showEmojiPicker ? (
              <div
                ref={emojiRef}
                className="absolute bottom-[430px] right-[380px]"
              >
                <Picker
                  theme="light"
                  data={data}
                  onEmojiSelect={(emoji) => selectEmoji(emoji)}
                />
              </div>
            ) : null}
          </span>
        </div>
      </div>
    </div>
  );
}

export default TextArea;
