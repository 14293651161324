import React from "react";
import PropTypes from "prop-types";
import DocumentEdit from "assets/svgs/DocumentEdit.svg";
import Download from "assets/svgs/Download.svg";
import { downloadFile } from "utils";

const FileAttachment = ({ attachments, title }) => {
  const handleDownload = (item) => {
    let url, fileName;
    if (typeof item === "string") {
      url = item;
      fileName = item.substring(item.lastIndexOf("/") + 1);
    } else if (typeof item === "object" && item.url) {
      url = item.url;
      fileName = item.name || item.url.substring(item.url.lastIndexOf("/") + 1);
    }

    if (url) {
      downloadFile(url, fileName);
    }
  };

  const getFileName = (item) => {
    if (typeof item === "string") {
      return item.substring(item.lastIndexOf("/") + 1);
    } else if (typeof item === "object" && item.url) {
      return item.name || item.url.substring(item.url.lastIndexOf("/") + 1);
    }
    return "Unknown File";
  };

  if (!attachments || attachments.length === 0) return null;

  return (
    <div>
      <label className={`text-sm md:text-base text-gray3`}>{title}</label>
      {attachments.map((item, i) => (
        <div key={i}>
          <div className="row-flex items-center gap-2 mt-3 bg-background rounded-[4px] px-2 py-1 w-fit">
            <img src={DocumentEdit} className="h-4 w-4" alt="icon" />
            <span className="text-[10px] md:text-[12px] font-[400] text-[#6F6F7C]">
              {getFileName(item)}
            </span>
            <img
              src={Download}
              className="h-4 w-4 cursor-pointer"
              alt="icon"
              onClick={() => handleDownload(item)}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

FileAttachment.propTypes = {
  attachments: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string, // URL as a string
      PropTypes.shape({
        url: PropTypes.string.isRequired,
        name: PropTypes.oneOfType([PropTypes.string, PropTypes.object]), // Name can be string or object
      }),
    ])
  ).isRequired,
  title: PropTypes.string.isRequired,
};

export default FileAttachment;
