import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SendBubble, ReceiveBubble } from './ChatBubble';
import { getChatByParticipants } from 'store/chat/chatActions';
function IndivualChat({ chatContainerRef, containerHeightClass }) {
  const { chat, loading, selectedChatParticpant } = useSelector(
    (state) => state.chat
  );
  const userId = localStorage.getItem('user_id');
  const chatRef = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedChatParticpant?.customer || selectedChatParticpant?.chatId) {
      chatRef.current = setInterval(() => {
        dispatch(
          getChatByParticipants({
            participants: [
              selectedChatParticpant.customer,
              selectedChatParticpant.installer,
            ],
          })
        );
      }, 2000);
    }
    return () => {
      clearInterval(chatRef.current);
    };
  }, [selectedChatParticpant.customer, selectedChatParticpant.chatId]);

  return (
    <div
      ref={chatContainerRef}
      className={`p-8 chat-container 
        shadow-xl overflow-scroll flex-column justify-start
        gap-7  w-full h-[500px ${containerHeightClass}`}
    >
      {chat?.messages?.length > 0 &&
        chat.messages?.map((current, index) => {
          return current.sender === userId ? (
            <SendBubble key={index} {...current} messages={chat.messages} />
          ) : (
            <ReceiveBubble key={index} {...current} messages={chat.messages} />
          );
        })}
    </div>
  );
}

export default IndivualChat;
