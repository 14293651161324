import {
  getCustomerNotifications,
  getDashboardDataCustomer,
} from "./customerActions";

const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  notifications: [],
  dashboardData: {},
  loading: false,
};

const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCustomerNotifications.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCustomerNotifications.fulfilled, (state, action) => {
        state.loading = false;
        state.notifications = action.payload;
      })
      .addCase(getCustomerNotifications.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getDashboardDataCustomer.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDashboardDataCustomer.fulfilled, (state, action) => {
        state.dashboardData = action.payload;
        state.loading = false;
      })
      .addCase(getDashboardDataCustomer.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default customerSlice.reducer;
