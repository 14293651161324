import { axiosInstance } from "api/axios";
const { createAsyncThunk } = require("@reduxjs/toolkit");

export const createBid = createAsyncThunk(
  "/bid/create",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post("/bids", payload);
      return response;
    } catch (err) {
      return rejectWithValue(err?.data?.error?.message);
    }
  }
);

export const getBidsBySiteIdInCustomer = createAsyncThunk(
  "/bid/getBidsBySiteIdInCustomer",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/bids/customer/${id}`);

      return {
        bids: response.bids,
        companyName: response.companyName,
      };
    } catch (err) {
      return rejectWithValue(err?.data?.message);
    }
  }
);

export const updateBidStatus = createAsyncThunk(
  "/bid/updateBidStatus",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.patch(
        `/bids/status/${payload.id}`,
        payload.data
      );
      return response;
    } catch (err) {
      return rejectWithValue(err?.data?.message);
    }
  }
);

export const getBidsByStatus = createAsyncThunk(
  "/bid/getBidsByStatus",
  async (status, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/bids/status?status=${status}`);
      return response.bids;
    } catch (err) {
      return rejectWithValue(err?.data?.message);
    }
  }
);

export const updateBid = createAsyncThunk(
  "/bid/updateBid",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(
        `/bids/${payload.id}`,
        payload.data
      );
      return response;
    } catch (err) {
      return rejectWithValue(err?.data?.message);
    }
  }
);

export const getStartedProjects = createAsyncThunk(
  "/bid/getStartedProjects",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`bids/completed`);
      return response.bids;
    } catch (err) {
      return rejectWithValue(err?.data?.message);
    }
  }
);

export const addProjectDate = createAsyncThunk(
  "/bid/addProjectDate",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`/projects`, { ...data });

      return response.data;
    } catch (err) {
      return rejectWithValue(err?.data?.message);
    }
  }
);

export const getCompletedProjects = createAsyncThunk(
  "/bids/details",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/bids/details`);
      return response?.data;
    } catch (err) {
      return rejectWithValue(err?.data?.message);
    }
  }
);

export const getProjectByBidId = createAsyncThunk(
  "/project/by-bid-id",
  async (bidId, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/projects/${bidId}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err?.response?.data?.message || err?.message);
    }
  }
);

export const updateProjectDate = createAsyncThunk(
  "/bid/updateProjectDate",
  async ({ id, projectEndDate, isCompleted }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(`/projects/${id}`, {
        projectEndDate,
        isCompleted,
      });

      return response.data;
    } catch (err) {
      return rejectWithValue(err?.data?.message);
    }
  }
);


export const getInstallerBids = createAsyncThunk(
  "/bid/getInstallerBids",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/bids/installer`);
      return response.bids;
    } catch (err) {
      return rejectWithValue(err?.data?.message);
    }
  }
);