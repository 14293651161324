import React from "react";
import sms from "assets/svgs/sms.svg";
import Location from "assets/svgs/location_2.svg";
import Profile from "assets/svgs/profile-circle.svg";
import Global from "assets/svgs/global.svg";
import { useSelector } from "react-redux";

function Address() {
  const { installers: installer } = useSelector((state) => state.installer)

  const handleClick = (email) => {
    window.location = `mailto:${email}`;
  };

  return (
    <div className="space-y-4">
      <div className="grid-container">
        <div className="flex flex-col gap-[10px]">
          <p className="text-[#98A2B3]">Representative Name</p>
          <span className="inline-flex gap-[10px]">
            <img src={Profile} alt="icon" />
            <p className="text-sm md:text-base text-[#5E6278] font-[500]">
              {installer.name}
            </p>
          </span>
        </div>
        <div className="flex flex-col gap-[10px]">
          <p className="text-[#98A2B3]">Email Address</p>
          <span className="inline-flex gap-[10px]">
            <img src={sms} alt="icon" />
            <a href={`mailto:${installer.email}`}><p className="text-sm md:text-base text-[#5E6278] font-[500]">{installer.email}</p></a>
          </span>
        </div>
        <div className="flex flex-col gap-[10px]">
          <p className="text-[#98A2B3]">Website Name</p>
          <span className="inline-flex gap-[10px]">
            <img src={Global} alt="icon" />
            <p onClick={() => window.open(installer.website)} className="text-sm md:text-base text-[#5E6278] font-[500]">
              {installer.website}
            </p>
          </span>
        </div>
        <div className="flex flex-col gap-[10px]">
          <p className="text-[#98A2B3]">Address</p>
          <span className="inline-flex gap-[10px]">
            <img src={Location} alt="icon" />
            <p className="text-sm md:text-base text-[#5E6278] font-[500]">
              {installer.address}
            </p>
          </span>
        </div>
        <div className="flex flex-col gap-[10px]">
          <p className="text-[#98A2B3]">Postcode</p>
          <span className="inline-flex gap-[10px]">
            <img src={Location} alt="icon" />
            <p className="text-sm md:text-base text-[#5E6278] font-[500]">
              {installer.postcode}
            </p>
          </span>
        </div>
      </div>
    </div>
  );
}

export { Address };
