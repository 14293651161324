import React from "react";
import { Loader } from "./Loader";
const PRIMARY = "bg-primary text-white hover:bg-primaryDark";
const SECONDARY =
  "bg-secondary text-primary font-semibold border-[1px] border-none";
const BORDERED =
  "bg-white text-primary font-semibold border-[1px] hover:border-[2px] border-primary";

const Button = ({
  text,
  variant,
  leftIcon,
  rightIcon,
  loading,
  className,
  disabled,
  ...rest
}) => {
  return (
    <button

      disabled={loading || disabled}
      className={`min-w-[123px] px-4 rounded-[8px] h-[40px] xl:h-[45px] capitalize flex flex-row items-center justify-center gap-2 font-medium  ${variant === "secondary"
        ? SECONDARY
        : variant === "bordered"
          ? BORDERED
          : PRIMARY
        } ${loading ? "!opacity-50 cursor-not-allowed" : ""} ${className}`}
      {...rest}
    >
      {loading ? <Loader /> : null}
      {leftIcon && !loading ? (
        <img src={leftIcon} className="h-5 w-5" alt="icon" />
      ) : null}
      <span>{text}</span>
      {rightIcon && !loading ? (
        <img src={rightIcon} className="h-5 w-5" alt="icon" />
      ) : null}
    </button>
  );
};

export { Button };
