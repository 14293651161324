import { ProjectCompletedDate } from "components/customer";
import { PageLoader } from "components/elements";
import NoDataPlaceholder from "components/global/NoDataFound";
import { BidCard } from "components/installer";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const ProjectsCompleted = () => {
  const { id } = useParams();
  const { loading, completedProjects } = useSelector((state) => state.bid);
  const [selectedProject, setSelectedProject] = useState(null);

  // Effect to set selectedProject once completedProjects are available
  useEffect(() => {
    const found = completedProjects.find((item) => item._id === id);
    if (found) {
      const structured = {
        ...found?.bidDetails,
        ...found,
        project: {
          projectStartedDate: found.projectStartedDate || "",
          projectEndDate: found.projectEndDate || "",
          isCompleted: found.isCompleted || false,
        },
        installerId: {
          ...found.installerDetails,
        },
      };
      delete structured.bidDetails; // Remove bidDetails as per your structure

      setSelectedProject(structured);
    }
  }, [completedProjects, id]); // Re-run this effect when `completedProjects` or `id` changes

  if (loading) {
    return (
      <div className="w-full h-[90vh] flex items-center justify-center">
        <PageLoader />
      </div>
    );
  }

  if (!selectedProject) {
    return <NoDataPlaceholder />;
  }

  return (
    <div className="flex flex-col relative gap-8 w-full h-full py-4 md:py-8 px-1">
      <BidCard
        type="projectCompleted"
        site={selectedProject?.siteId}
        finalBid={selectedProject?.bidDetails?.cost?.finalBid}
        customer={
          selectedProject?.customerId || selectedProject?.customerDetails
        }
        technologies={selectedProject?.technologies}
        bid={selectedProject}
      />
      <ProjectCompletedDate
        userRole="installer"
        projectStartedDate={selectedProject?.project?.projectStartedDate}
        projectCompletedDate={selectedProject?.project?.projectEndDate}
        isCompleted={selectedProject?.project?.isCompleted}
      />
    </div>
  );
};

export { ProjectsCompleted };
