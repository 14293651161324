import React from 'react';
import Person from 'assets/svgs/Person.svg';
import { useSelector } from 'react-redux';
import { Avatar as UserAvatar, Space } from 'antd';
import { UserOutlined } from '@ant-design/icons';

export const Avatar = () => (
  <UserAvatar
    style={{
      backgroundColor: '#87d068',
      width: '54px',
      height: '54px',
      display: 'flex', // Use flexbox
      alignItems: 'center', // Center vertically
      justifyContent: 'center', // Center horizontally
    }}
    icon={<UserOutlined />}
  />
);

function ChatHeader() {
  const { selectedChatParticpant } = useSelector((state) => state.chat);

  return (
    <div className='py-4 w-full bg-[#FDFFFB]  pl-8 border-b'>
      <div className='row-flex items-center  gap-4'>
        {selectedChatParticpant?.image ? (
          <img
            src={selectedChatParticpant?.image}
            className='rounded-full w-[54px] h-[54px]'
          />
        ) : (
          <Avatar />
        )}
        <div>
          <p className='font-medium text-xl text-[#344054]'>
            {selectedChatParticpant?.name || 'John Doe'}
          </p>
        </div>
      </div>
    </div>
  );
}

export default ChatHeader;
