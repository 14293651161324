import {
  addProjectDate,
  createBid,
  getBidsBySiteIdInCustomer,
  getBidsByStatus,
  getCompletedProjects,
  getProjectByBidId,
  getStartedProjects,
  updateBid,
  updateBidStatus,
  getInstallerBids,
  updateProjectDate,
} from "./bidActions";

const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  loading: false,
  error: "",
  bids: [],
  companyName: "",
  startedProjects: [],
  completedProjects: [],
  project: {},
  projectCompleteLoading: false,
  openContactModal: false,
};

const bidSlice = createSlice({
  name: "bid",
  initialState,
  reducers: {
    clearProject: (state) => {
      state.project = {};
    },
    updateBidById: (state, action) => {
      const updatedBid = action.payload;
      const index = state.bids.findIndex((bid) => bid._id === updatedBid._id);
      if (index !== -1) {
        state.bids[index] = updatedBid;
      }
    },
    setContactModal: (state, action) => {
      state.openContactModal = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createBid.pending, (state) => {
        state.loading = true;
      })
      .addCase(createBid.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(createBid.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getBidsBySiteIdInCustomer.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBidsBySiteIdInCustomer.fulfilled, (state, action) => {
        state.bids = action.payload.bids;
        state.companyName = action.payload.companyName;
        state.loading = false;
      })
      .addCase(getBidsBySiteIdInCustomer.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateBidStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateBidStatus.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateBidStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getBidsByStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBidsByStatus.fulfilled, (state, action) => {
        state.bids = action.payload;
        state.loading = false;
      })
      .addCase(getBidsByStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateBid.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateBid.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateBid.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getStartedProjects.pending, (state) => {
        state.loading = true;
      })
      .addCase(getStartedProjects.fulfilled, (state, action) => {
        state.startedProjects = action.payload;
        state.loading = false;
      })
      .addCase(getStartedProjects.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(addProjectDate.pending, (state) => {
        state.loading = true;
      })
      .addCase(addProjectDate.fulfilled, (state, action) => {
        const {
          projectStartedDate,
          projectEndDate = null,
          bidId,
        } = action.payload;
        const found = state.startedProjects.find((item) => item._id === bidId);
        if (found) {
          found.project = {
            projectStartedDate,
            projectEndDate,
          };
        }
        state.loading = false;
      })
      .addCase(addProjectDate.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(getCompletedProjects.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCompletedProjects.fulfilled, (state, action) => {
        state.completedProjects = action.payload;
        state.loading = false;
      })
      .addCase(getCompletedProjects.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getProjectByBidId.pending, (state) => {
        state.projectCompleteLoading = true;
      })
      .addCase(getProjectByBidId.fulfilled, (state, action) => {
        state.project = action.payload;
        state.projectCompleteLoading = false;
      })
      .addCase(getProjectByBidId.rejected, (state, action) => {
        state.projectCompleteLoading = false;
        state.error = action.payload;
      })
      .addCase(updateProjectDate.pending, (state) => {
        state.projectCompleteLoading = true;
      })
      .addCase(updateProjectDate.fulfilled, (state, action) => {
        const { projectStartedDate, projectEndDate, bidId, isCompleted } =
          action.payload;
        const found = state.startedProjects.find((item) => item._id === bidId);
        if (found) {
          found.project = {
            projectStartedDate,
            projectEndDate,
            isCompleted,
          };
        }
      })
      .addCase(updateProjectDate.rejected, (state, action) => {
        state.projectCompleteLoading = false;
        state.error = action.payload;
      })
      .addCase(getInstallerBids.pending, (state) => {
        state.loading = true;
      })
      .addCase(getInstallerBids.fulfilled, (state, action) => {
        state.bids = action.payload;
        state.loading = false;
      })
      .addCase(getInstallerBids.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// Export clearProject action
export const { clearProject, updateBidById, setContactModal } =
  bidSlice.actions;

export default bidSlice.reducer;
