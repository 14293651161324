import React from "react";

function TechnologiesList({ technologies, type }) {
  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-column">
        <p className={`text-[#98A2B3]  text-[16px]`}>
          Technology Type {type === "profile" ? "" : "& Capacities Offered"}
        </p>
        {type === "profile" && (
          <p className="text-[#98A2B3] font-[400] text-[15px]">
            What technologies do you supply along with the minimum and maximum
          </p>
        )}
      </div>
      <div className="grid-container">
        {technologies?.length > 0 &&
          technologies.map(
            ({ technologyType, maxCapacity, minCapacity, capacity }) => {
       const displayCapacity =
         minCapacity && maxCapacity
           ? `${minCapacity}-${maxCapacity} kW`
           : `${Math.round((capacity || 0) * 10) / 10 || "N/A"} kW`;


              return (
                <div
                  key={technologyType}
                  className="flex flex-col xl:flex-row xl:items-center gap-2"
                >
                  <p className="text-sm md:text-base antialiased text-[#5E6278] font-[500] capitalize">
                    {technologyType === "gas chp"
                      ? "Gas CHP"
                      : technologyType === "biomass chp"
                      ? "Biomass CHP"
                      : technologyType}
                  </p>
                  <p className="text-[10px] md:text-xs text-[#749816] bg-[#F4FFD7] rounded-full py-1 w-fit sm:max-w-[150px] px-2 font-[500]">
                    {displayCapacity}
                  </p>
                </div>
              );
            }
          )}
      </div>
    </div>
  );
}

export { TechnologiesList };
