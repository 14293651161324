import AWS from 'aws-sdk';
import { SECRET_ACCESS_KEY, BUCKET_NAME, ACCESS_KEY_ID } from './keys';

const s3 = new AWS.S3({
  accessKeyId: ACCESS_KEY_ID,
  secretAccessKey: SECRET_ACCESS_KEY,
  region: 'ap-south-1',
});

function getFileType(file) {
  const fileType = file.type || '';
  if (fileType) {
    return fileType.split('/')[1];
  }
  return '';
}

async function uploadFileToS3(file) {
  const sanitizedFileName = file.name.replace(/\s+/g, "_");
  const trimmedFileName =
    sanitizedFileName.length > 20
      ? sanitizedFileName.substring(0, 20)
      : sanitizedFileName;
  const fileName = `${Date.now()}-${trimmedFileName}`;

  const params = {
    Bucket: BUCKET_NAME,
    Key: fileName,
    ContentType: file.type,
    Body: file,
    ACL: "public-read",
  };

  const result = await s3.putObject(params).promise();

  const { region } = s3.config;
  const s3ObjectLink = `https://${BUCKET_NAME}.s3.${region}.amazonaws.com/${encodeURIComponent(
    fileName
  )}`;

  const fileType = getFileType(file);
  const fileSize = file.size;
  const url = s3ObjectLink;

  return { fileSize, fileType, fileName, url, result };
}

export default uploadFileToS3;
