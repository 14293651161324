import React from 'react'

function BorderHeading({ heading }) {
  return (
    <div className="pb-3 w-full border-b border-[#DEE2E6]">
      <p className="text-[20px] font-semibold text-[#3F4254]">{heading}</p>
    </div>
  )
}

export { BorderHeading }
