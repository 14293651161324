import { AuthLayout } from "components/auth";
import { Button, PasswordInput } from "components/elements";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Logo from "assets/svgs/Logo.svg";
import { useNavigate } from "react-router-dom";
import { ANIMATE_TOP_LEFT } from "utils/animations";
import { useDispatch, useSelector } from "react-redux";
import { installerResetPassword } from "store/auth/authActions";
import { toast } from "react-toastify";

const ResetPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const email = sessionStorage.getItem("email");
  const { loading } = useSelector((state) => state.auth);

  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const { control, setValue, handleSubmit } = useForm();

  const navigateToPath = (path) => {
    navigate(path);
  };

  const onSubmit = (data) => {
    setPasswordError("");
    setConfirmPasswordError("");
    if (!data.password) {
      setPasswordError("Password is required!");
    } else if (!data.confirmPassword) {
      setConfirmPasswordError("Confirm Password is required!");
    } else if (data.password !== data.confirmPassword) {
      setConfirmPasswordError("Confirm Password must match with password!");
    } else {
      dispatch(
        installerResetPassword({
          email,
          password: data.password,
        })
      )
        .unwrap()
        .then((_res) => {
          navigateToPath("/otp-success");
        })
        .catch((_err) => {
          toast.error("Password reset failed! Try again later!", {
            toastId: "passwordReset",
          });
        });
    }
  };

  return (
    <AuthLayout>
      <div className="w-full max-w-[500px] m-auto flex-column items-start justify-center gap-6 md:gap-8 px-4 py-12">
        <img src={Logo} alt="Logo" className="md:hidden w-[60%] m-auto mb-6" />
        <div
          className={`w-full flex flex-col items-center md:items-start gap-2 ${ANIMATE_TOP_LEFT}`}
        >
          <p className="text-xl md:text-3xl font-[600]">Reset Password</p>
          <p className="text-sm text-darkGray">
            Already have an account?{" "}
            <span
              className="text-primary cursor-pointer"
              onClick={() => navigateToPath("/login")}
            >
              Sign In
            </span>
          </p>
        </div>
        <PasswordInput
          variant="white"
          label="Password"
          name="password"
          control={control}
          setValue={setValue}
          passwordStrength
          error={passwordError}
        />
        <PasswordInput
          variant="white"
          label="Confirm Password"
          name="confirmPassword"
          control={control}
          setValue={setValue}
          error={confirmPasswordError}
        />
        <Button
          type="submit"
          onClick={handleSubmit(onSubmit)}
          text="Reset Password"
          className={`w-full ${ANIMATE_TOP_LEFT}`}
          loading={loading}
        />
      </div>
    </AuthLayout>
  );
};

export { ResetPassword };
