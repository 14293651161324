import React, { createContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import io from 'socket.io-client';
import {
  getBidsBySiteIdInCustomer,
  getBidsByStatus,
  getStartedProjects,
} from 'store/bid/bidActions';
import { setMessageCount } from 'store/chat/chatSlice';
import { getDashboardDataCustomer } from 'store/customer/customerActions';
import { getDashboardData } from 'store/installer/installerActions';
import { setIsNotifications } from 'store/installer/installerSlice';
import {
  getAcceptedInvitesForInstaller,
  getInvitesForCustomer,
} from 'store/invites/invitesAction';

export const SocketContext = createContext();

export const SocketProvider = ({ children }) => {
  // Next 2 lines just to make sure component is rerendered when login!!
  const { loading: _bidLoading } = useSelector((state) => state.bid);
  const { loading: _sitesLoading } = useSelector((state) => state.sites);
  const dispatch = useDispatch();
  const [socket, setSocket] = useState(null);
  const token = localStorage.getItem('token');

  let userId;
  const installerId = localStorage.getItem('installerId');
  const customerId = localStorage.getItem('customerId');
  if (installerId) {
    userId = installerId;
  } else if (customerId) {
    userId = customerId;
  }

  useEffect(() => {
    if (!token) return;
    const socketInstance = io.connect('https://marketplace.ongen.energy', {
      extraHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
    setSocket(socketInstance);

    socketInstance.on('connect', () => {
    });

    socketInstance.on("connect_error", (err) => {});

    socketInstance.emit('room', userId);

    socketInstance.on("roomJoined", (data) => {});

    socketInstance.on("success", (data) => {});
    socketInstance.on('notifications', (data) => {
      if (data.type !== 'message') {
        dispatch(getDashboardDataCustomer());
        dispatch(getDashboardData());
        toast.success(data.message);
      }
      dispatch(setIsNotifications(true));

      if (data.type === 'bidRequestedFromInstaller') {
        dispatch(
          getInvitesForCustomer({
            siteId: data.siteId,
            customerId: customerId,
          })
        );
      } else if (data.type === 'bidRequestedFromCustomer') {
        dispatch(getAcceptedInvitesForInstaller(installerId));
      } else if (data.type === 'siteVisitUpdate') {
        dispatch(getBidsBySiteIdInCustomer(data.siteId));
      } else if (data.type === 'updateBidStatus') {
        dispatch(getBidsByStatus(data.status));
      } else if (data.type === 'getStartedProjects') {
        dispatch(getStartedProjects());
      } else if (data.type === 'message') {
        dispatch(setMessageCount());
      }
    });

    return () => {
      socketInstance.removeAllListeners();
      socketInstance.disconnect();
    };
  }, [token, userId, customerId, dispatch, installerId]);

  return (
    <SocketContext.Provider value={{ socket }}>
      {children}
    </SocketContext.Provider>
  );
};
