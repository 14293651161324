import { PageLoader } from 'components/elements';
import { BidInProcessCard } from 'components/installer';
import { INSTALLER_DATA } from 'mock/data';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getBidsByStatus } from 'store/bid/bidActions';

const ScheduleSiteVisit = () => {
  const dispatch = useDispatch();
  const { loading, bids } = useSelector((state) => state.bid);
  const installerId = localStorage.getItem('installerId');

  useEffect(() => {
    dispatch(getBidsByStatus("shortListed,scheduled"));
  }, [dispatch, installerId]);

  return (
    <>
      <div className="flex flex-col gap-2 py-4">
        <p className="text-base font-medium text-darkGray2">
          {INSTALLER_DATA.scheduleSiteVisit["mainText"]}
        </p>
      </div>
      {loading ? (
        <div className="w-full h-[90vh] m-auto flex items-center justify-center">
          <PageLoader />
        </div>
      ) : bids.length === 0 ? (
        <p>No Bids Found!</p>
      ) : (
        <div className="flex-column gap-8 w-full h-full py-4 md:py-8 px-1">
          {bids?.map((bid) => {
            if (bid.status === "shortListed" || bid.status === "scheduled") {
              return (
                <BidInProcessCard
                  type="scheduleVisit"
                  key={bid._id}
                  site={bid?.siteId}
                  bidId={bid?._id}
                  customerInfo={bid?.customerInfo}
                  customer={bid?.customerId}
                  cost={bid?.cost}
                  status={bid?.status}
                  bid={bid}
                />
              );
            }
            return null;
          })}
        </div>
      )}
    </>
  );
};

export { ScheduleSiteVisit };
