import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { getInstallerNotifications } from 'store/installer/installerActions';
import { PageLoader } from 'components/elements';
import { NotificationCard } from 'components/macros/NotificationCard';
import { getCustomerNotifications } from 'store/customer/customerActions';
import { setIsNotifications } from 'store/installer/installerSlice';
import { INSTALLER_DATA } from 'mock/data';

function Notifications() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [notifications, setNotifications] = useState();

  useEffect(() => {
    dispatch(setIsNotifications(false));
    let userRole = localStorage.getItem('user_role');
    if (userRole === 'installer') {
      dispatch(getInstallerNotifications())
        .unwrap()
        .then((res) => {
          setNotifications(res);
          setLoading(false);
        });
    }
    if (userRole === 'customer') {
      dispatch(getCustomerNotifications())
        .unwrap()
        .then((res) => {
          setNotifications(res);
          setLoading(false);
        });
    }
  }, [dispatch]);

  if (loading) {
    return <PageLoader />;
  }

  return (
    <div className='py-4 md:py-8 px-1'>
      <p className='text-base font-medium pb-4 text-darkGray2'>
        {INSTALLER_DATA.notifications['mainText']}
      </p>
      <div className='flex-column gap-4'>
        {notifications.map((current, index) => {
          return <NotificationCard {...current} key={index} />;
        })}
      </div>
    </div>
  );
}

export { Notifications };
