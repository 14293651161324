import React, { useEffect } from "react";
import { Controller } from "react-hook-form";

const Checkbox = ({ title, control, name, onChange, className, checked, error }) => {
  const [isChecked, setIsChecked] = React.useState(false);

  useEffect(() => {
    if (checked) {
      setIsChecked(true);
    }
  }, [checked]);

  return (
    <>
      <label className={`checkbox-container ${className}`}>
        <Controller
          defaultValue=""
          name={name}
          control={control}
          render={({ field }) => (
            <input
              type="checkbox"
              checked={checked}
              {...field}
              onChange={(e) => {
                onChange && onChange(e);
                setIsChecked(e.target.checked);
              }}
            />
          )}
        />
        <p className={`${isChecked ? "font-medium" : ""}`}>{title}</p>
        <span className="checkmark"></span>
      </label>
      {error && <p className="error-message">{error}</p>}
    </>
  );
};

export { Checkbox };
