import zxcvbn from 'zxcvbn';
import moment from 'moment';

export const checkPasswordStrength = (password) => {
  return zxcvbn(password).score;
};

export function getTimeDifference(dateString) {
  return moment(dateString).fromNow();
}

export function getFileNameFromS3Url(s3Url) {
  const urlParts = s3Url.split('/');
  const fileNameWithExtension = urlParts[urlParts.length - 1];
  const fileName = fileNameWithExtension.split('-').pop();

  return fileName;
}

export const isImage = (url) => {
  const imageExtensions = [
    'jpg',
    'jpeg',
    'png',
    'gif',
    'bmp',
    'tiff',
    'tif',
    'svg',
  ]; // Add more image extensions if needed
  const extension = url?.split('.')?.pop().toLowerCase();
  return imageExtensions.includes(extension);
};

export function formatFileSize(fileSize) {
  const units = ['B', 'KB', 'MB', 'GB', 'TB'];
  let size = fileSize;
  let unitIndex = 0;

  while (size >= 1024 && unitIndex < units.length - 1) {
    size /= 1024;
    unitIndex++;
  }

  const formattedSize = size.toFixed(2) + units[unitIndex];
  return formattedSize;
}

export function getDisplayedFileName(fileName, length) {
  const fileExtension = fileName?.substr(fileName.lastIndexOf('.') + 1);

  if (fileName?.length > length) {
    return fileName?.substring(0, length - 4) + '.. .' + fileExtension;
  } else {
    return fileName;
  }
}

export const filterFilesByExtension = (files, extensions) => {
  const filteredFiles = {
    imageFiles: [],
    otherFiles: [],
  };

  files.forEach((file) => {
    const fileExtension = file.fileName
      ?.substr(file.fileName.lastIndexOf('.') + 1)
      .toLowerCase();

    if (extensions.includes(fileExtension)) {
      filteredFiles.imageFiles.push(file);
    } else {
      filteredFiles.otherFiles.push(file);
    }
  });

  return filteredFiles;
};


export function downloadFile(url, fileName) {
  fetch(url)
    .then(response => response.blob())
    .then(blob => {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .catch(error => console.error('Error downloading file:', error));
}

export function downloadLocalFile(file) {
  const link = document.createElement('a');
  link.href = URL.createObjectURL(file);
  link.download = file.name;
  link.target = '_blank';
  link.rel = 'noopener noreferrer';
  link.click();
  URL.revokeObjectURL(link.href);
}

export function toCamelCase(str) {
  return str
    .toLowerCase()
    .split(' ')
    .map((word, index) => {
      if (index === 0) {
        return word;
      }
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join('');
}

export function convertCamelToNormal(camelCaseStr) {
  if (!camelCaseStr) return "";

  // Add a space before capital letters and convert the first letter to uppercase
  const normalString = camelCaseStr
    .replace(/([a-z])([A-Z])/g, "$1 $2") // Insert space before uppercase letters
    .replace(/^./, (str) => str.toUpperCase()); // Capitalize the first letter

  return normalString;
}