import React, { useEffect, useState } from 'react';

// Images
import LocationIcon from 'assets/svgs/Location.svg';
import DocumentTextIcon from 'assets/svgs/DocumentText.svg';
import FileUpload from 'components/macros/FileUpload';
import FileLists from 'components/macros/FileLists';
import Edit from 'assets/svgs/Edit.svg';
import uploadFileToS3 from 'utils/s3Upload';
import { useDispatch } from "react-redux";
import { addAttachmentsToSite } from "store/sites/sitesActions";
import { Button } from "components/elements";
import { toast } from "react-toastify";
import { TechnologyList } from "components/macros/Technologies";
import { Tooltip } from "antd";

const ProjectDetails = ({
  installerId,
  site,
  showMatchCheck,
  companyName,
  selectedProject,
}) => {
  const [fileList, setFileList] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const dispatch = useDispatch();
  const [uploading, setUploading] = useState(false);
  const role = localStorage.getItem("user_role");

  useEffect(() => {
    setFileList(site?.attachments || []);
  }, [site?.attachments]);

  const handleAttach = async () => {
    setUploading(true);
    let updatedFileList = fileList.filter((file) => typeof file === "string");
    const newFilesToUpload = fileList.filter(
      (file) => typeof file !== "string"
    );

    try {
      if (newFilesToUpload.length > 0) {
        const uploadedFiles = await Promise.all(
          newFilesToUpload.map(uploadFileToS3)
        );
        const uploadedUrls = uploadedFiles.map((item) => item.url);
        updatedFileList = [...updatedFileList, ...uploadedUrls];
      } else {
      }

      if (
        JSON.stringify(updatedFileList) !== JSON.stringify(site?.attachments)
      ) {
        dispatch(
          addAttachmentsToSite({ id: site?._id, attachments: updatedFileList })
        );
        setFileList(updatedFileList);
        toast.success("Attachments updated successfully!", {
          toastId: "attachmentsUpdated",
        });
      } else {
      }
    } catch (error) {
      toast.error("Error uploading files. Please try again.", {
        toastId: "uploadError",
      });
    } finally {
      setTimeout(() => {
        setUploading(false);
        setIsEditing(false);
      }, 300);
    }
  };

  const getClassName = (isMatch) =>
    `text-xs bg-${
      isMatch ? "secondary text-primary" : "lightGray text-white"
    } rounded-full py-1 px-1 font-[500]`;
  return (
    <div className="relative lg:min-w-[720px] max-w-[720px] h-fit bg-white flex-column gap-4 px-2 md:px-4 xl:px-6 py-4 md:py-6 xl:py-8 rounded-xl shadow-md">
      <div className="flex-column md:row-flex md:items-center justify-between gap-2 md:gap-4 lg:gap-8">
        <div className="row-flex items-center gap-1 md:gap-2">
          <img
            src={DocumentTextIcon}
            alt="doc"
            className="w-[0.9rem] h-[1rem] md:w-[22px] md:h-[24px]"
          />
          <p className="text-[#344054] text-base lg:text-lg capitalize font-[400]">
            Project Details
          </p>
        </div>
        <div className="row-flex gap-1">
          <img
            src={LocationIcon}
            alt="location"
            className="w-[0.9rem] h-[1rem] md:w-[1.1rem] md:h-[1.2rem]"
          />
          <p className="text-xs md:text-sm text-darkGray">
            {site?.addressOfSite
              ? site?.addressOfSite
              : "123 Apartment, Floor 1 ABC 123 123 Apartment."}
          </p>
        </div>
      </div>
      {role === "installer" && (
        <div className="flex-column gap-1">
          <p className="text-sm lg:text-[15px] text-[#98A2B3] font-[400]">
            Company Name
          </p>
          <p className="text-sm md:text-base text-[#5E6278] font-[500]">
            {companyName}
          </p>
        </div>
      )}

      <div className="flex-column gap-1">
        <p className="text-sm lg:text-[15px] text-[#98A2B3] font-[400]">
          Name of Site
        </p>
        <p className="text-sm md:text-base text-[#5E6278] font-[500]">
          {site?.siteName
            ? site?.siteName
            : site?.nameOfSite
            ? site?.nameOfSite
            : "Site name here"}
        </p>
      </div>
      {installerId && installerId?.name && (
        <div>
          <p className="text-sm lg:text-[15px] text-[#98A2B3] font-[400]">
            Name of Installer
          </p>
          <p className="text-sm md:text-base text-[#5E6278] font-[500]">
            {installerId?.name}
          </p>
        </div>
      )}
      <div className="flex-column gap-2">
        <TechnologyList
          technologies={selectedProject?.technologies}
          oldTechnologies={site?.technologies}
        />

        <div>
          <p className="text-[#98A2B3]">Region</p>
          <p className="text-[#5E6278] font-[500]">{site?.region}</p>
        </div>
      </div>

      <p className="text-primary cursor-pointer text-sm mt-4">
        <div>
          <div className="flex gap-2">
            <p className="text-[#98A2B3] font-bold text-[15px]">Attachments</p>
            <img onClick={() => setIsEditing((prev) => !prev)} src={Edit} />
          </div>
          <p className="text-[15px] font-[400] text-[#98A2B3]">
            Relevant documents about project!
          </p>
        </div>
      </p>

      <FileUpload
        fileList={fileList}
        setFileList={setFileList}
        maxSize={20}
        maxFiles={5}
        label="Upload attachment!"
        disablePreview={true}
        viewOnly={!isEditing}
      />

      {isEditing && (
        <div className="flex justify-end gap-4 mt-4">
          <Button
            variant="bordered"
            text="Close"
            loading={uploading}
            onClick={() => setIsEditing(false)}
          >
            Cancel
          </Button>
          <Button
            loading={uploading}
            variant="primary"
            text="Save"
            onClick={() => handleAttach()}
          >
            Save
          </Button>
        </div>
      )}
      <FileLists fileList={[]} />
    </div>
  );
};

export { ProjectDetails };
