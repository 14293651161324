export const INSTALLER_DATA = {
  dashboard: {
    mainText:
      'Welcome to OnGen Marketplace. There are 5 steps required to get a project installed by an installer using Marketplace.',
    helperText:
      'To start bidding on projects, select invitations to see projects in your area that you can submit bids for.',
    points: [
      {
        text: 'Submit initial bids for projects that you’ve been invited to bid on.',
        linkText: 'Invitations',
        href: '/dashboard/invitations',
      },

      {
        text: 'Or if there are no invitations, look in opportunities to request to bid for projects',
        linkText: 'Opportunities',
        href: '/dashboard/opportunities',
      },
      {
        text: 'Schedule a site visit with the customer',
        linkText: 'Schedule Site Visit',
        href: '/dashboard/scheduleSiteVisit',
      },
      {
        text: 'Once you’ve visited the site, then submit a final project bid.',
        linkText: 'Submit Final Bid',
        href: '/dashboard/confirmedSiteVisit',
      },
    ],
  },
  invitations: {
    mainText:
      'Here is a list of projects that you’ve been invited to bid on as an installer. Click below to submit the initial bid for this project. You’ll be able to submit a refined bid later if you are chosen by the installer to visit the site.',
    helperText:
      'If there are no invitations, go to the Opportunities page to select projects that you would be interested in bidding.',
  },
  bidInReview: {
    mainText:
      'Here you will find each site that your organisation has bid for and is being reviewed by the customer.',
  },
  scheduleSiteVisit: {
    mainText:
      'Your bid has been shortlisted, next step is to schedule a site visit with the customer. Please suggest two suitable dates when you are available for a site visit',
  },
  confirmedSiteVisit: {
    mainText:
      'Now that you have visited the site and know more about the project you can submit a final quote. Add attachments if you would like to add a more detailed quote',
  },
  projectStarted: {
    mainText:
      'Below you can view the projects that your organisation has successfully bid for.',
  },
  notifications: {
    mainText:
      'Below you can view the projects that your organisation has successfully bid for.',
  },
  messages: {
    mainText:
      'Here it is possible to engage with projects that you have been shortlisted for. This can be used to contact customers for any additional required information regarding the project',
  },
};

export const CUSTOMER_DATA = {
  dashboard: {
    mainText:
      'Welcome to OnGen Marketplace. There are 4 steps required to get a project installed by an installer using Marketplace.',
    helperText:
      'To get started, select ‘Send Invites’ and start inviting installers to bid for each of your projects.',
    points: [
      {
        text: 'Send invites to appropriate installers',
        linkText: 'Send Invites',
        href: '/dashboard/sentInvites',
      },
      {
        text: 'Make a decision to approve or decline initial project bids',
      },
      {
        text: 'Schedule a site visit with each of the shortlisted installers',
      },
      {
        text: 'Choose the preferred installer',
      },
    ],
  },
  sendInvites: {
    mainText:
      'To start inviting installers to bid for your projects, click on each project and send an invitation to installers that you are interested in. By inviting an installer to bid, the installer will be able to submit an initial bid for the project.',
    helperText:
      'The installers listed below operate in the geographical area of the project and install the technologies listed in the project. The installers are sorted in descending order with the installers that offer all of your required technologies listed at the top. If an installer does not offer one or more of the technologies you require, the technology capacity will appear grey. You can still send invites to installers that do not offer your entire range of required technologies.'
  },
};


export const COST_INFO = {
  materialCost: "Material Cost",
  laborCost: "Labor Cost",
  totalCost: "Total Cost",
  additionalCost: "Access Cost",
};