import { ArrowRight } from "assets/svgs/icons";
import React from "react";
import HoverImage from "assets/svgs/dashboard/DbCardHover.svg";

const DashboardCard = ({
  title,
  count,
  color,
  image,
  btnText,
  className,
  onClick,
}) => {
  return (
    <div
      className={`group relative w-[300px] md:w-[342px] h-[200px] flex-column justify-between px-6 py-4 rounded-md shadow-md overflow-hidden ${className}`}
      style={{
        "&:hover": {
          backgroundColor: color,
        },
      }}
    >
      <div className="flex-column gap-2 group-hover:text-white">
        <p className="text-lg text-[#344054">{title}</p>
        <p
          className={`text-[64px] font-[300] leading-[64px] ${className}`}
          // style={{ color }}
        >
          {count}
        </p>
      </div>
      <div
        className="row-flex items-center gap-2 cursor-pointer"
        onClick={onClick}
      >
        <p
          className={`text-base leading-6 font-[400] ${className}`}
          // style={{ color }}
        >
          {btnText}
        </p>
        <div className="group-hover:hidden">
          <ArrowRight color={color} />
        </div>
        <div className="hidden group-hover:block">
          <ArrowRight color="#fff" />
        </div>
      </div>
      <img
        src={image}
        alt="icon"
        className="group-hover:hidden absolute -bottom-14 -right-14"
      />
      <img
        src={HoverImage}
        alt="icon"
        className="hidden group-hover:block absolute -bottom-14 -right-14"
      />
    </div>
  );
};

export { DashboardCard };
