import {
  getInstallerById,
  updateInstallerProfile,
  getInstallerNotifications,
  getDashboardData,
} from './installerActions';

const { createSlice } = require('@reduxjs/toolkit');

const initialState = {
  loading: false,
  error: '',
  installers: [],
  updatedInstaller: null,
  user: {},
  notifications: [], // Added property to store notifications
  dashboardData: {},
  isNotifications: false,
};

const installerSlice = createSlice({
  name: 'installer',
  initialState,
  reducers: {
    setIsNotifications: (state, action) => {
      state.isNotifications = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInstallerById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getInstallerById.fulfilled, (state, action) => {
        state.loading = false;
        state.installers = action.payload;
        state.user = action.payload;
      })
      .addCase(getInstallerById.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateInstallerProfile.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateInstallerProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.updatedInstaller = action.payload;
      })
      .addCase(updateInstallerProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getInstallerNotifications.pending, (state) => {
        state.loading = true;
      })
      .addCase(getInstallerNotifications.fulfilled, (state, action) => {
        state.loading = false;
        state.notifications = action.payload;
      })
      .addCase(getInstallerNotifications.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getDashboardData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDashboardData.fulfilled, (state, action) => {
        state.loading = false;
        state.dashboardData = action.payload;
      })
      .addCase(getDashboardData.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { setIsNotifications } = installerSlice.actions;
export default installerSlice.reducer;
