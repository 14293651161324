import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Logo from "assets/svgs/Logo.svg";
import { Button, OtpInput } from "components/elements";
import { ANIMATE_RIGHT } from "utils/animations";
import {
  installerVerifyOtp,
  installerForgetPassword,
  customerVerifyOtp,
} from "store/auth/authActions";

const Otp = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const [otpCode, setOtpCode] = useState("");
  const [otpError, setOtpError] = useState("");

  const isCustomer = sessionStorage.getItem("role") === "customer";

  // Get email for installer or session for customer
  const email = sessionStorage.getItem("email");
  const session = sessionStorage.getItem("session");

  const navigateToPath = (path) => {
    navigate(path);
  };

  const submitOtp = () => {
    setLoading(true);
    setOtpError("");

    if (!otpCode) {
      setOtpError("OTP is required!");
      return;
    }

    const action = isCustomer
      ? customerVerifyOtp({ session, code: otpCode, email })
      : installerVerifyOtp({ email: email, otp: otpCode });

    // when role is customer, we need to verify the otp with session and code then
    dispatch(action)
      .unwrap()
      .then((_res) => {
        setLoading(false);
        if (isCustomer) {
          localStorage.setItem("token", _res.token);
          localStorage.setItem("customerId", _res.customer._id);
          localStorage.setItem("user_role", _res.customer.role);
          setTimeout(() => {
            toast.success("OTP verified successfully!", { toastId: "otp" });
            sessionStorage.clear();
            navigateToPath("/dashboard/customer");
          }, 1400);
        } else {
          toast.success("OTP verified successfully!", { toastId: "otp" });
          navigateToPath("/reset-password");
        }
      })
      .catch((err) => {
        setLoading(false);
        setOtpError(err || "Invalid OTP!");
      });
  };

  const resendOtpCode = () => {
    const action = installerForgetPassword({ email: email });
    dispatch(action)
      .unwrap()
      .then(() => {
        toast.success("OTP resent successfully!", { toastId: "otpResent" });
      })
      .catch((err) => {
        toast.error(err || "Something went wrong!", { toastId: "otpError" });
      });
  };

  return (
    <div className="w-screen h-screen overflow-hidden">
      <div className="relative flex-column items-center mt-[3%] 1420:mt-[10%] px-4 py-4 md:py-0 gap-8 md:gap-15 1420:gap-18">
        <img src={Logo} alt="Logo" />
        <div className="flex-column items-center justify-center gap-3">
          <p
            className={`text-center text-xl md:text-[1.5rem] font-[600] ${ANIMATE_RIGHT}`}
          >
            Enter Verification Code
          </p>
          <p className="text-center text-sm md:text-lg text-darkGray">
            Please enter your {isCustomer ? "6" : "4"}-digit verification code
            sent to your email <span className="text-primary">{email}</span>
          </p>
        </div>
        <OtpInput
          length={isCustomer ? 6 : 4}
          otpCode={otpCode}
          setOtpCode={setOtpCode}
        />
        {otpError && <p className="text-red-500">{otpError}</p>}
        <div className="flex-column items-center justify-center gap-3">
          <Button
            text="Submit"
            onClick={submitOtp}
            className={ANIMATE_RIGHT}
            loading={loading}
            disabled={loading}
          />
          <p className="text-xs md:text-sm text-darkGray font-medium">
            Didn't receive an email?{" "}
            <span
              className={`text-primary ${
                loading ? "cursor-not-allowed" : "cursor-pointer"
              }`}
              onClick={
                isCustomer
                  ? () => {
                      navigate("/customer/login");
                    }
                  : resendOtpCode
              }
            >
              {isCustomer ? "Login again" : "Try Again"}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export { Otp };
