import { axiosInstance } from "api/axios";
const { createAsyncThunk } = require("@reduxjs/toolkit");

export const createInvite = createAsyncThunk(
  "/invite/create",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post("/invitations", payload);
      return response;
    } catch (err) {
      return rejectWithValue(err?.data?.error?.message);
    }
  }
);

export const getInvitesForCustomer = createAsyncThunk(
  "/invite/getInvitesForCustomer",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        "/invitations/customer",
        payload
      );
      return response;
    } catch (err) {
      return rejectWithValue(err?.data?.message);
    }
  }
);

export const getAcceptedInvitesForInstaller = createAsyncThunk(
  "/invite/getAcceptedInvitesForInstaller",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `/accepted/invitations/installer/${id}`
      );
      return response;
    } catch (err) {
      return rejectWithValue(err?.data?.message);
    }
  }
);

export const updateInvitationStatus = createAsyncThunk(
  "/invite/updateInvitationStatus",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(
        `invitations/${payload.id}/status`,
        payload.data
      );
      return response;
    } catch (err) {
      return rejectWithValue(err?.data?.message);
    }
  }
);
